import React from 'react';
import './PlanItem.css'
import Button from '../../../components/design/buttons/Button';
import { useTranslation } from 'react-i18next';

const PlanItem = ({ plan, onClick, className }: any) => {
    const { id, name, price } = plan;
    const {t} = useTranslation();
    
    return (
        <div className={"plan-item" + (className ? " " + className : "")}>
            <h3 style={{fontSize: "24px", lineHeight: "0.6", marginTop: "8px"}}
            >{name}</h3>
            <span className='price_item'> {price} {t('global.sar')}</span>
            <div className="row py-3 gap-md-3">
                <div className="col-md-12 col-6">
                    <Info name={t('details.validity')} logo="/media/svgs/clock_.svg" info={plan.validityTimeInDays + " " + t('plans.days')} />
                </div>
                <div className="col-md-12 col-6">
                    <Info name={t('store.network')} logo="/media/svgs/rss.svg" info={plan.provider? plan.provider.name : ""} />
                </div>
            </div>

            {onClick && <Button inner={t("store.buy")} onClick={() => onClick(id)} color="primary px-4 buy_btn" />}
        </div>
    );
    };

const Info = ({ name, logo, info}: any) => {
    return (
        <div className="info">
            <div className="d-flex" style={{ alignItems: 'center', gap: 10 }}>
                <img src={logo} alt={name} width={36} height={36} className="info-logo" />
                <div className="d-flex flex-column">
                    <span className='text-muted'>{name}</span>
                    <span>{info}</span>
                </div>
            </div>
        </div>
    );
    };


export default PlanItem;

export { Info };
    