import { useLocation } from "react-router-dom";
import { ProgressBar } from "../../stepper/steps/Step";
import '../../stepper/steps/Step.css';
import { useEffect, useState } from "react";
import { createOrder } from "../../../services/orders.service";
import { useTranslation } from "react-i18next";
import Header from "../../../components/header/header";
import Footer from "../../../components/footer/footer";
import { toast } from "react-toastify";

const CreateOrder = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    const {t, i18n} = useTranslation();
    const [order, setOrder] = useState<any>(null);
    const [error, setError] = useState<any>(null);
    const fetchOrder = async () => {
        const response = (await createOrder(id, i18n.language)) as any;
        if(response.data.apiStatusCode === 400)
        {

            toast.error(t(response.data.errorMessage))
            setError(response.data.errorMessage);
        } else
        setOrder(response!.data.returnData[0]);
    }    

    useEffect(() => {
        if(order)
        {
            localStorage.setItem('order', JSON.stringify(order));
            window.location.href = '/order';
        }
        else
        fetchOrder();
    }, [order]);

    return (
        <>
            <div className="container" style={{minHeight: "80vh"}}>
                <Header/>
            <div className="d-flex justify-content-center align-items-center flex-column " style={{minHeight: "80vh"}}>
                {error ?<></>:<div style={{width: "36px", height: "36px"}}>
                <ProgressBar onProgressEnd={() => {
                    
                }} time={300} />
                </div>}
                <p className="text-muted mx-3 ">
                    {
                        error ? error : t('Create Your Order')
                    }
                    
                </p>
            </div>
            </div>
            <Footer/>
        </>
    )


}

export default CreateOrder;