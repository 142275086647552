import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/design/buttons/Button";
import Footer from "../../../components/footer/app_footer";
import { sendDAta } from "../../../services/stepper.service";
import ProgressBar from "../../stepper/components/ProgressBar";
import PlanBar from "../components/planBar";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {ShimmerTitle} from 'react-shimmer-effects-18';
import './recomendedPlans.css';

// TbCash
// HiOutlineGlobeAlt
// TbPhone

// HiOutlineUpload
import { HiOutlineUpload } from "react-icons/hi";

const RecommandedPlans = () => {
    const [loading, setLoading] = useState(true);
//     const [show, setShow] = useState(false);
//     const handleShow = () => setShow(true);
    
//     const [selectedPlan, setSelectedPlan] = useState<any>(null);
//     const handleClose = () => setSelectedPlan(null);
    const id = useParams().id;
    const [plans, setPlans] = useState([]);
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const copyToClipboard = (text: string) => {
        const output = JSON.parse(localStorage.getItem('output')!)
        .map((items: any) => {
            return items.value.join("-");
        });
        const str_output = output.join(".");
        navigator.clipboard.writeText(text+'/'+str_output);
        toast.success(t("global.copied_to_clipboard"));
    }


    const fetchPlans = async () => {
        const output = localStorage.getItem('output');
      
        if (output) {
            const tosend = id ?
            id.split(".").map((item: string, index: number) => {
                return {
                    value: item.split("-"),
                    qustionKey: index + 1
                }
            }
            )
            :
            JSON.parse(output);
            // send to the backend
            await sendDAta(tosend, i18n.language).then((res) => {

                const data = res.data.returnData.map((plan: 
                    { id: any; name: any; imageUrl: any; planTypeName: any; price: any; smSForCurrentProvider: any; localInternt: any; minutesInSideProvider: any; }
                    , index: number) => {
                    return {
                        id: plan.id,
                        name: plan.name,
                        image: plan.imageUrl,
                        title: plan.name,
                        type: plan.planTypeName,
                        price: plan.price,
                        messages: plan.smSForCurrentProvider,
                        internet: plan.localInternt,
                        calls: plan.minutesInSideProvider,
                        isActive: index === 0 ? true : false,
                    }
                });


                setPlans(data);
                setLoading(false);
            }
            );
        }
    }

            


    useEffect(() => {
        fetchPlans();
    }, []);
    


    
    return (
        <div className="recommended-plans">
            <div className="container ">
        <div className="my-3">
            <div className="row justify-content-between">
            <div className="col">
            <Button color="circle"   isfullWidth={false} onClick={()=>{
                navigate(-2);
            }} inner={
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.50977 18.4898L18.4898 1.50977" stroke="#2F1952" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M18.4898 18.4898L1.50977 1.50977" stroke="#2F1952" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            } />
            </div>
                <div className="col justify-content-end d-flex">
                {
                    [1, 2, 3].map((step) => {
                        return (
                            <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 0C4.13333 0.133333 8.0001 0.933327 11.6001 2.39999C15.6001 4.06666 19.0335 6.29998 21.9001 9.09998C24.7668 11.9666 27.0333 15.3 28.7 19.1C30.3666 22.9 31.2 26.9667 31.2 31.3C31.2 32.7 31.1001 34.0667 30.9001 35.4H14.9001C15.3001 34.1333 15.5 32.7667 15.5 31.3C15.5 29.1667 15.1 27.1667 14.3 25.3C13.5 23.3667 12.3668 21.7 10.9001 20.3C9.43348 18.9 7.66676 17.7667 5.6001 16.9C3.86676 16.2333 2 15.8667 0 15.8V0Z" fill="#3EB6AE"/>
                            </svg>
                        );
                    })
                }
                </div>
            </div>
        </div>
        {/* <div className="row my-5">
            <div className="col-12">
                
            <ProgressBar currentStep={10} steps={10} />
            <Button color="circle mt-3"   isfullWidth={false} onClick={()=>{
                navigate('/stepper/9');
            }} inner={
               <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={{transform: i18n.language === 'ar' ? 'rotate(180deg)' : 'rotate(0deg)'}}>
               <path d="M15.95 31.3669C16.2666 31.3669 16.5833 31.2502 16.8333 31.0002C17.3166 30.5169 17.3166 29.7169 16.8333 29.2335L7.59997 20.0002L16.8333 10.7669C17.3166 10.2835 17.3166 9.48353 16.8333 9.0002C16.35 8.51686 15.55 8.51686 15.0666 9.0002L4.94996 19.1169C4.46663 19.6002 4.46663 20.4002 4.94996 20.8835L15.0666 31.0002C15.3166 31.2502 15.6333 31.3669 15.95 31.3669Z" fill="#292D32"/>
               <path d="M6.1165 21.25H34.1665C34.8498 21.25 35.4165 20.6833 35.4165 20C35.4165 19.3167 34.8498 18.75 34.1665 18.75H6.1165C5.43317 18.75 4.8665 19.3167 4.8665 20C4.8665 20.6833 5.43317 21.25 6.1165 21.25Z" fill="#292D32"/>
               </svg>
               
            } />
            
            </div>
        </div> */}
        </div>
            <div className="container px-4 mb-5">
            <div className="d-flex align-items-center justify-content-between mb-5">
                <h3>{t("recommended_plans.best_for_you")}</h3>
                <div>
                    <Button inner=
                {
                    <div className="d-flex align-items-center justify-content-center">
                        {/* <i className="fas fa-share-alt"></i> */}
                        <HiOutlineUpload style={{fontSize: "1.5rem"}} />
                        <p className="mx-3 my-0">{t("details.share")}</p>
                    </div>
                } onClick={()=>{
                    copyToClipboard(window.location.href.split('recommended')[0]+"recommended");

                }} color="outline" isfullWidth={true} />
                </div>
            </div>
            {
            !loading?
                plans.slice(0, 5).map((plan) => (
                    <PlanBar plan={plan}  />
                ))
                :
                [0,1,2,3].map((item: number) => (
                    <div className="plans-wrapper" key={item}>
                    <ShimmerTitle line={3} gap={10} variant="primary" />
                </div>
                )
                )}
            </div>
            <div className="pt-5 pt-lg-0">
            <div className="d-none d-lg-block">
                    <Footer />
            </div>
            </div>
            {/* <Modal
        show={selectedPlan !== null}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        fullscreen={true}
        >
        <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <PlanScreen plan={selectedPlan} />
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal> */}
        </div>
    );
    
};

export default RecommandedPlans;
