import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
// import s'./Store.css';
import React, { useState } from 'react';
import {  getPlans } from '../../services/plans.service';
import { useTranslation } from 'react-i18next';
import PlanItem, { Info } from '../store/components/PlanItem';
import Button from '../../components/design/buttons/Button';
import { HiOutlineThumbUp } from 'react-icons/hi';
import SideInfo from '../store/components/sideInfo';
import './Order.css'
import { IoIosArrowForward, IoIosClipboard, IoIosDocument } from 'react-icons/io';
import { IoCopy, IoCopyOutline } from 'react-icons/io5';
import Animate from '../about/Animate';
import { Carousel } from 'react-bootstrap';
import { getCountryById, getProviderById } from '../../services/providers.service';
import { createOrder, getOrderById } from '../../services/orders.service';
import Card from '../../components/design/cards/card';

const Order = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    const [order, setOrder] = React.useState<OrderType>();
    const itemId = useParams().item;
    const [page, setPage] = React.useState<any>(1);
    const [plans, setPlans] = React.useState<any>([]);
    const {t,i18n} = useTranslation();
    const [plan, setPlan] = React.useState<any>(null);
    const [country, setCountry] = React.useState<any>(null);
    const updatePlans = async (
        {
            page,
            country,
        }: any = {
            page: 1,
            country: id,
        }
    ) => {
        const dat = await getPlans({
            countryId: country,
            offset: 1,
            limit: 6,
        }, i18n.language);
        setPlans(dat.returnData);
    }
    const updatePlan = async (
        {
            planId
        }: any 
    ) => {
        getPlans({
            planId,
        }, i18n.language).then(async (dat: any) => {
            const provider = await getProviderById(dat.returnData[0].providerId, i18n.language);
            setPlan({
                ...dat.returnData[0],
                provider,
            });
            console.log(dat.returnData[0]);
        });
        


    }
    const GetOrder = async () => {
        if(!id)
        {
            const order_ = JSON.parse(localStorage.getItem('order')!);
            const order_details = JSON.parse(localStorage.getItem('order_details')!);
            order_.price = order_details.amount;
            order_.qty = order_details.qty;
            
            setOrder(order_.map((item: any) => {
                return {
                    ...item,
                    messages: item.smSForCurrentProvider === -1 ? t("plans.unlimited") : item.smSForCurrentProvider + " " + t("plans.message"),
                    internet: item.localInternt === -1 ? t("plans.unlimited") : item.localInternt/1000 + " " + t("plans.gb"),
                    calls: item.minutesInSideProvider === -1 ? t("plans.unlimited") : item.minutesInSideProvider + " " + t("plans.minutes"),
                }
            }
            ));
            return;
        }
        let dat = (await getOrderById(+id!, i18n.language)) as any;
        const order_ = dat.data.returnData.orderDeitalsDtos[0];
        console.log(order_);
        setOrder(
            {
                    messages: order_.smSForCurrentProvider === -1 ? t("plans.unlimited") : order_.smSForCurrentProvider + " " + t("plans.message"),
                    internet: order_.localInternt === -1 ? t("plans.unlimited") : order_.localInternt/1000 + " " + t("plans.gb"),
                    calls: order_.minutesInSideProvider === -1 ? t("plans.unlimited") : order_.minutesInSideProvider + " " + t("plans.minutes"),
                    ...order_,
            }
           
        );
        const country_id = dat.data.returnData.orderDeitalsDtos[0].countryId;
        const resss = await getCountryById(country_id, i18n.language);
        setCountry(resss.data.returnData[0]);
    }

    React.useEffect(() => {
        GetOrder();
    
        if(itemId)
        updatePlan({
            planId: itemId,
        });
        
        updatePlans();
    }
    , [id, page, i18n.language, itemId]);

    if(!order)
    return <></>;

    return (
        <>
        <div style={{backgroundImage: "url(/media/hero/bg2.png)", backgroundRepeat:"no-repeat", backgroundSize: "cover", backgroundPosition: "top"}}>
        <div className="container">
            <Header />
            <div className="row store" style={{minHeight: "80vh"}}>
            <div className="col-lg-4  col-sm-12 col-xs-12 order-lg-1 order-1 order-lg-2 order-xs-2">
                        <SideInfo 
                        background="https://images.unsplash.com/photo-1491884662610-dfcd28f30cfb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2624&q=80"
                        is_bg={true}
                        elements={[
                            <GetPDFReceipt plan={plan} order={order} />,
                            <div className="get_pdf">
                                        <div className="d-flex justify-content-between mt-3" style={{minHeight: '70px'}}>
                                            <div className="d-flex ">
                                                <img className="" src={order.imageUrl} alt={order.planName} width="44" height="44" style={{borderRadius: '6px'}} />

                                                <div className="mx-3">
                                                    <strong className="plan_title">{order.planName}</strong>
                                                    <p className="font-weight-light text-muted">{order.contractTypeName}</p>
                                                </div>
                                            </div>
                                            <strong style={{color: '#3EB6AE', whiteSpace:"nowrap"}}>{order.price} {t('global.sar')}</strong>
                                        </div>
                                        <div className="d-flex d-lg-block justify-content-between" style={{maxWidth: '300px'}}>
                                                <div className="d-flex align-items-center my-3 ">
                                                    {/* <i className="fas fa-phone fa-1x mx-3" style={{color: '#3EB6AE'}}></i> */}
                                                    {/* <HiOutlinePhone className="mx-3" style={{color: '#3EB6AE', fontSize: '1.5rem'}} /> */}
                                                    <div className="me-3">                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.6 18.45 8.51 17.52 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z" stroke="#2F1952" stroke-width="1.5" stroke-miterlimit="10"/>
                                                        </svg>
                                                        </div>

                                                    <div>
                                                        <p className="font-weight-light  m-0">{order.calls}</p>
                                                        <p className="font-weight-light text-muted m-0">{t("plans.calls")}</p>
                                                    </div>
                                                </div>
                                                <div className="d-none d-lg-flex
                                                 align-items-center my-3">
                                                    {/* <i className="fas fa-comment fa-1x mx-3" style={{color: '#3EB6AE'}}></i> */}
                                                    {/* <FaRegEnvelope className="mx-3" style={{color: '#3EB6AE', fontSize: '1.5rem'}} /> */}
                                                    
                                                    <div className="me-3">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" stroke="#2F1952" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                                                <path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="#2F1952" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>
                                                                </div>

                                                    <div>
                                                        <p className="font-weight-light m-0">{order.messages}</p>
                                                        <p className="font-weight-light text-muted m-0">{t("plans.messages")}</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center my-3">
                                                    {/* <i className="fas fa-globe fa-1x mx-3" style={{color: '#3EB6AE'}}></i> */}
                                                    {/* <FiGlobe className="mx-3" style={{color: '#3EB6AE', fontSize: '1.5rem'}} /> */}
                                                    <div className="me-3">

                                                                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#2F1952" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M7.99998 3H8.99998C7.04998 8.84 7.04998 15.16 8.99998 21H7.99998" stroke="#2F1952" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M15 3C16.95 8.84 16.95 15.16 15 21" stroke="#2F1952" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M3 16V15C8.84 16.95 15.16 16.95 21 15V16" stroke="#2F1952" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M3 9.0001C8.84 7.0501 15.16 7.0501 21 9.0001" stroke="#2F1952" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>
                                                    </div>

                                                    <div>
                                                        <p className="font-weight-light  m-0">{order.internet}</p>
                                                        <p className="font-weight-light text-muted m-0">{t("plans.internet")}</p>
                                                    </div>
                                                </div>

                                        </div>
                                        
                            </div>
                        ]}
                        noBg={[]}
                        />
                </div>
                <div className="col-lg-8  col-sm-12 col-xs-12 order-lg-2 order-2 order-lg-1 order-xs-1">
                    {/* back button */}
                    
                    {/* header */}
                    <OrderHeader />
                    {/* <CountryHeader /> */}
                    <div className="order_tabs my-4">
                    <h1>{t("store.install_esim")}</h1>
                    <p>{t("store.step_1")}</p>
                    <TabsInfo order={order} />
                    <p>{t("store.step_2")}</p>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="input_gray">
                                <div className="d-flex justify-content-between align-items-center">
                                    <span>
                                    {t("store.celluar_data")}
                                    </span>
                                    <span className='d-flex align-items-center'>
                                        <span>{t("store.on")}</span>
                                        <IoIosArrowForward />
                                    </span>
                                </div>
                            </div>
                            {t("store.access_data")}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="input_gray">
                                <div className="d-flex justify-content-between align-items-center">
                                    <span>
                                    {t("store.celluar_data")}
                                    </span>
                                    <span className='d-flex align-items-center'>
                                        <span>{t("store.on")}</span>
                                        <IoIosArrowForward />
                                    </span>
                                </div>
                            </div>
                            {t("store.access_data")}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="input_gray">
                                <div className="d-flex justify-content-between align-items-center">
                                    <span>
                                    {t("store.celluar_data")}
                                    </span>
                                    <span className='d-flex align-items-center'>
                                        <span>{t("store.on")}</span>
                                        <IoIosArrowForward />
                                    </span>
                                </div>
                            </div>
                            {t("store.access_data")}
                        </div>
                    </div>
                    </div>
                    {/* plans */}
                   
                    {/* pagination */}
                    {/* <div className="pagination">
                        <div className="pagination-item" onClick={() => setPage(page - 1)}>Prev</div>
                        <div className="pagination-item" onClick={() => setPage(page + 1)}>Next</div>
                    </div> */}
                    
                </div>
               
            </div>

        </div>
        </div>
        <Footer />
        </>
    );
}

const OrderHeader = () => {
    const {t,i18n} = useTranslation();
    return (
        <div className="header_order">
            <div className="d-flex">
            <div className="d-flex flex-column justify-content-start align-items-start gap-2">
                <strong>{t("store.the_faster")}</strong>
                <p>{t("store.you_have_signed")}</p>
                <div className='row mb-3'>
                  <div className='col-6'>
                    <a href="https://apps.apple.com/qa/app/tygo/id1619973559?platform=iphone](https://apps.apple.com/qa/app/tygo/id1619973559?platform=iphone)" target="_blank">
                    <img src="/media/buttons/apple.svg" width={124} />
                    </a>
                  </div>
                  <div className='col-6'>
                    <a href='https://play.google.com/store/apps/details?id=app.tygo.sa' target="_blank">
                    <img src="/media/buttons/google.svg" width={124} />
                    </a>
                  </div>
                </div>
            </div>
            <img src="/media/order_page.png" width={300} className='d-none d-lg-block' />
            </div>
        </div>
    );
}

// const Get PDF Receipt
const GetPDFReceipt = ({
    plan,
    order
}: {
    plan?: any,
    order?: any
}) => {

    const {t,i18n} = useTranslation();
    // if(!plan) return null;
    return (
        <div className="get_pdf">
             <div className="table_info">
                                <div>
                                    <img src="/media/gifs/done.gif" width={64} />
                                    <strong>{t("store.payment_success")}</strong>
                                </div>
                                <div className="d-flex justify-content-between table_item">
                                    <span>{t("store.price")}</span>
                                    <span>{order.price} {t('global.sar')}</span>
                                </div>
                                <div className="d-flex justify-content-between table_item">
                                    <span>{t("store.quantity")}</span>
                                    <span>{order.qty}</span>
                                </div>
                                <div className="d-flex justify-content-between table_item">
                                    <span>{t("store.fees")}</span>
                                    <span>{0} {t('global.sar')}</span>
                                </div>
                                <div className="devider"></div>
                                <div className="d-flex justify-content-between ">
                                    <span>{t("store.total")}</span>
                                    <strong>{order.price} {t('global.sar')}</strong>
                                </div>
                                <div className="devider"></div>

                                <Button inner={
                                    <>
                                    <img src="/media/svgs/download.svg" />
                                    {t("store.get_pdf_receipt")}
                                    </>
                            } color="outline" isfullWidth={true} />

                            </div>
        </div>
    );
}


function TabsInfo({order}:any) {
    const {t,i18n} = useTranslation();
    const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };
  return (
    // Install eSim / Access Data
    <>
    <Tabs
      defaultActiveKey="qr"
      id="uncontrolled-tab-example"
      className="mb-3"
      fill
    >

      <Tab eventKey="qr" title={t("store.qr_code"+'')}>
        <div className="qr_code d-flex flex-column align-items-center justify-content-center gap-5 my-5">
            <h1>{t("store.scan")}</h1>
            {imageError ? (
        <img src="/media/qrerror.svg" width={200} />
      ) : (
        <img
          src={order?.qrCode}
          width={200}
          onError={handleImageError}
          alt="QR Code"
        />
      )}<p><img src="/media/svgs/download_.svg" width={20} /> {t("store.save_as_image")}</p>
        </div>

      </Tab>
      <Tab eventKey="manual" title={t("store.manual"+'')}>
        <div className="row">
            <Slider
            inners={[[
                {
                    title: t("esim.section5.element1.item1.title"),
                    content: t("esim.section5.element1.item1.sub_title"),
                    image: "/media/slider/1.png",
                    image_mobile: "/media/slider/1_.png"
                },
                {
                    title: t("esim.section5.element1.item2.title"),
                    content: t("esim.section5.element1.item1.sub_title"),
                    image: "/media/slider/2.png",
                    image_mobile: "/media/slider/2_.png"
                },
                {
                    title: t("esim.section5.element1.item3.title"),
                    content: t("esim.section5.element1.item1.sub_title"),
                    image: "/media/slider/3.png",
                    image_mobile: "/media/slider/3_.png"
                },
                {
                    title: t("esim.section5.element1.item3.title"),
                    content: t("esim.section5.element1.item1.sub_title"),
                    image: "/media/slider/4.png",
                    image_mobile: "/media/slider/4_.png"

                },
                {
                    title: t("esim.section5.element1.item3.title"),
                    content: t("esim.section5.element1.item1.sub_title"),
                    image: "/media/slider/5.png",
                    image_mobile: "/media/slider/5_.png"
                },
            ],[
                {
                    title: t("esim.section5.element2.item1.title"),
                    content: t("esim.section5.element1.item1.sub_title"),
                    image: "/media/slider/1.png",
                    image_mobile: "/media/slider/1_.png"
                },
                {
                    title: t("esim.section5.element2.item2.title"),
                    content: t("esim.section5.element1.item1.sub_title"),
                    image: "/media/slider/2.png",
                    image_mobile: "/media/slider/2_.png"
                },
                {
                    title: t("esim.section5.element2.item3.title"),
                    content: t("esim.section5.element1.item1.sub_title"),
                    image: "/media/slider/3.png",
                    image_mobile: "/media/slider/3_.png"

                },
                {
                    title: t("esim.section5.element2.item3.title"),
                    content: t("esim.section5.element1.item1.sub_title"),
                    image: "/media/slider/4.png",
                    image_mobile: "/media/slider/4_.png"
                },
                {
                    title: t("esim.section5.element2.item3.title"),
                    content: t("esim.section5.element1.item1.sub_title"),
                    image: "/media/slider/5.png",
                    image_mobile: "/media/slider/5_.png"
                }

            ]]}
            seperator={<div className="slider-seperator">
                <div className="slider-seperator__title">
                    <h1>Choose your plan</h1>
                </div>
                <div className="slider-seperator__content">
                    <p>Choose your plan and get your eSIM</p>
                </div>
            </div>
            }
            />
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="input_gray">
                <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex flex-column">
                    <span>
                    {t("store.sm")}
                    </span>
                    <span className='text-muted'>
                    {order.sM_DP_Address}
                    </span>
                </div>
                <IoCopyOutline size={22} />
                </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="input_gray">
                <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex flex-column">
                    <span>
                    {t("store.activation_code")}
                    </span>
                    <span className='text-muted'>
                    {order.activationCode}
                    </span>
                </div>
                <IoCopyOutline size={22} />
                </div>
                </div>
            </div>

        </div>

      </Tab>
    </Tabs>
    </>
  );
}

const Slider =(
    props: {
        inners: any[][],
        seperator: any,
    }
)=> {
    const [index, setIndex] = React.useState(0);
    const [active, setActive] = React.useState(0);
    const [mobile, setMobile] = React.useState(false);
    const {t, i18n} = useTranslation();
  const handleSelect = (selectedIndex: any, e: any) => {
    setIndex(selectedIndex);
  };

    React.useEffect(() => { 
        if (window.innerWidth < 992) {
            setMobile(true);
        }
    }, [])


    return (
            <Animate delay={0.1}>
            <div className=''>
                        <Animate delay={0.2}>
                        <div className="d-flex my-4 gap-3" style={{maxWidth: "520px", margin: "0 auto"}}>
                        <Button inner={t("esim.section5.ios")}
                        onClick={
                            () => {
                                setActive(0)
                            }
                        } color={active === 0 ? "primary" : "secondary"}
                        isfullWidth={true} isrounded={true}/>  
                        <Button inner={t("esim.section5.android")}
                                                    onClick={
                            () => {
                                setActive(1)
                            }
                        } color={active === 1 ? "primary" : "secondary"}
                        isfullWidth={true} isrounded={true}/>
                        </div>
                        </Animate>
                        <Animate delay={0.3}>
                        <Carousel activeIndex={index} onSelect={handleSelect} controls={false} interval={null} indicators={false}>
                        {/* {props.inners[active].map((inner, index) => {
                            return (
                                <Carousel.Item>
                                    </Carousel.Item>
                            )
                        })} */}
                        <Animate delay={0.4}>
                       
                        </Animate>                                   
                        </Carousel>
                        </Animate>
                        <div className='d-block'>
                        <Carousel activeIndex={index} onSelect={handleSelect} interval={null} controls={false} indicators={false}>
                        {props.inners[active].map((inner, index) => {
                            return (
                                
                                <Carousel.Item>
                                    <img src={inner.image_mobile} 
                                     height={"384px"} style={{objectFit: "contain"}} className="d-block w-100 my-4" alt="..."/>
                                     {/* {inner.image} */}
                                     {/* './media/slider/1.png' */}
                                </Carousel.Item>
                            )
                        })}
                    </Carousel>
                        </div>
                        
                        <strong className='d-block m-auto text-center'>
                            <span>0{index+1} / 0{props.inners[active].length} </span>
                        </strong>
                        <Animate delay={0.5}>
                        <div className='d-flex my-4 justify-content-center justify-content-center'>
                            {Array.from({length: props.inners[0].length}).map((_, index_) => {
                                return (
                                        <div className='me-3' onClick={() => setIndex(index_)} style={{cursor: "pointer", width: "32px", height: "6px", borderRadius: "3px", backgroundColor: index === index_ ? "#3EB6AE" : "#DADADA"}}></div>
                                )
                            })}
                        </div>    
                        </Animate>     
                    
            </div>
            </Animate>
    )

}
class OrderType {

    imageUrl: string;
    planName: string;
    contractTypeName: string;
    price: number;
    internet: number;
    calls: number;
    messages: number;
    activationCode: string;
    bookedDate: string;
    checkOutId: string;
    checkOutPlans: any;
    coverage: string;
    createdAt: string;
    eSimImportId: number;
    esimStatistic: any;
    esimStatisticId: number;
    esimStatus: any;
    esimStatusId: number;
    expritationDate: any;
    iccid: string;
    id: number;
    importedDate: string;
    isDeleted: boolean;
    isKyc: boolean;
    isUnlimited: boolean;
    isVisible: boolean;
    mobileNumber: string;
    network: string;
    ownerId: string;
    planId: number;
    purchasePrice: number;
    qrCode: string;
    remainingData: number;
    sM_DP_Address: string;
    totalData: number;
    updatedAt: any;
    usedData: number;
    constructor(
        activationCode: string,
        bookedDate: string,
        checkOutId: string,
        checkOutPlans: any,
        coverage: string,
        createdAt: string,
        eSimImportId: number,
        esimStatistic: any,
        esimStatisticId: number,
        esimStatus: any,
        esimStatusId: number,
        expritationDate: any,
        iccid: string,
        id: number,
        importedDate: string,
        isDeleted: boolean,
        isKyc: boolean,
        isUnlimited: boolean,
        isVisible: boolean,
        mobileNumber: string,
        network: string,
        ownerId: string,
        planId: number,
        purchasePrice: number,
        qrCode: string,
        remainingData: number,
        sM_DP_Address: string,
        totalData: number,
        updatedAt: any,
        usedData: number,
        imageUrl: string,
        planName: string,
        contractTypeName: string,
        price: number,
        calls: number,
        messages: number,
        internet: number
    ) {
        this.activationCode = activationCode;
        this.bookedDate = bookedDate;
        this.checkOutId = checkOutId;
        this.checkOutPlans = checkOutPlans;
        this.coverage = coverage;
        this.createdAt = createdAt;
        this.eSimImportId = eSimImportId;
        this.esimStatistic = esimStatistic;
        this.esimStatisticId = esimStatisticId;
        this.esimStatus = esimStatus;
        this.esimStatusId = esimStatusId;
        this.expritationDate = expritationDate;
        this.iccid = iccid;
        this.id = id;
        this.importedDate = importedDate;
        this.isDeleted = isDeleted;
        this.isKyc = isKyc;
        this.isUnlimited = isUnlimited;
        this.isVisible = isVisible;
        this.mobileNumber = mobileNumber;
        this.network = network;
        this.ownerId = ownerId;
        this.planId = planId;
        this.purchasePrice = purchasePrice;
        this.qrCode = qrCode;
        this.remainingData = remainingData;
        this.sM_DP_Address = sM_DP_Address;
        this.totalData = totalData;
        this.updatedAt = updatedAt;
        this.usedData = usedData;
        this.imageUrl = imageUrl;
        this.planName = planName;
        this.contractTypeName = contractTypeName;
        this.price = price;
        this.internet = internet;
        this.calls = calls;
        this.messages = messages;

    }


}
export {Slider};
export default Order;
