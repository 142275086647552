


import { useEffect, useState } from 'react';
import './countries.css';
import { useTranslation } from 'react-i18next';
import Button, { ButtonSquare } from '../../../../components/design/buttons/Button';
import { Modal } from 'react-bootstrap';
import Animate from '../../../about/Animate';
import { useNavigate } from 'react-router-dom';
import { ShimmerTitle } from "react-shimmer-effects-18";

const Countries = (
    {countries} : {
        countries: any[],
    }
) => {
    const {t, i18n} = useTranslation();
    const [active, setActive] = useState(-1);
    const [mobile, setMobile] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const [max, setMax] = useState(20);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate();

    const [search, setSearch] = useState('');
    const [searchResult, setSearchResult] = useState<any[]>([]);
    const Search = (name: string) => {
        const res = countries.filter((country: any) => country.name.toLowerCase().includes(name.toLowerCase()))
        //some countries have the same id so we need to remove the duplicates typescript
        const unique = res
            .map((e: any) => e["id"])
            .map((e: any, i: any, final: any) => final.indexOf(e) === i && i)
            .filter((e: any) => res[e])
            .map((e: any) => res[e]);
        setSearchResult(unique);
    }

    const handleSearch = (e: any) => {
        setSearch(e.target.value);
        Search(e.target.value);
    }



    useEffect(() => {
        //if mobile
        if (window.innerWidth < 768) {
            setMobile(true);
        }

        // setMax(countries.length - (countries.length % 5));
    }, [countries.length])
    if (countries.length === 0) {
        return <>
                <div className="countries__section pb-5 mt-3">
       <div className="countries__wrapper pb-5 "

       >
         <div className="countries">

        {
            [1,2,3,4,5,6,7,8,9,10].map((item: number) => (
                <div className="country-wrapper" key={item}>
                    <ShimmerTitle line={3} gap={10} variant="primary" />
                </div>
            ))
        }
        </div>
        </div>
        </div>
        </>;
    }

    return (
        // background-size: contain;
        // background-repeat: no-repeat;
        // background-position: bottom;
        <div className="countries__section pb-5 mt-3">
       <div className="countries__wrapper pb-5 "

       >
        <Animate delay={0.6}>
                        <div className="search text-start">
                            <i className="fas fa-search search__icon me-3"/>
                            <input type="text" placeholder={t("esim.section2.search") || ''} className="search__input" onChange={handleSearch}/>
                        </div>
                        </Animate>
                        {
                            searchResult.length > 0
                            && search
                            ?
                            <div className="search__result__container">
                            <div className="search__result">
                                <div className="search__result__list">
                                    {
                                        searchResult.map((item, index) => {
                                            return (
                                                <div className="search__result__item" key={index}>
                                                    <div className="search__result__item__img" style={{backgroundImage: `url(${item.flagImageURL})`}}>
                                                    </div>
                                                    <div className="search__result__item__name">
                                                        {item.name}
                                                        <div className="search__result__item__name__code">
                                                        <p className='m-0'>
                        <span className="text-muted">
                        {t("esim.section2.from")}
                        </span>
                         {item.code} {item.currencyCode} / {t("esim.section2.gb")} </p>
                                                        </div>
                                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={{transform: i18n.language === 'en' ? "scale(-0.7)" : "scale(0.7)"}}><path d="M15.95 31.3669C16.2666 31.3669 16.5833 31.2502 16.8333 31.0002C17.3166 30.5169 17.3166 29.7169 16.8333 29.2335L7.59997 20.0002L16.8333 10.7669C17.3166 10.2835 17.3166 9.48353 16.8333 9.0002C16.35 8.51686 15.55 8.51686 15.0666 9.0002L4.94996 19.1169C4.46663 19.6002 4.46663 20.4002 4.94996 20.8835L15.0666 31.0002C15.3166 31.2502 15.6333 31.3669 15.95 31.3669Z" fill="#3eb6ae"></path><path d="M6.1165 21.25H34.1665C34.8498 21.25 35.4165 20.6833 35.4165 20C35.4165 19.3167 34.8498 18.75 34.1665 18.75H6.1165C5.43317 18.75 4.8665 19.3167 4.8665 20C4.8665 20.6833 5.43317 21.25 6.1165 21.25Z" fill="#3eb6ae"></path></svg>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            </div>
                            :
                            null

                        

                        }
            <div className="row">
            <div className='col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mb-4'>
                    <Animate delay={0.5} >
                    <ButtonSquare inner={t(
                        "esim.section2.button_local"
                    )} color="primary" isfullWidth={true} isrounded={true} onClick={() => {
                        // navigator('/stepper/1')
                    }}/>
                    </Animate>
                </div>

                <div className='col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mb-4 '>
                    <Animate delay={0.6} >
                        <ButtonSquare inner={t("esim.section2.button_regional")} 
                        color="primary-outline-primary"
                         isfullWidth={true} isrounded={true} onClick={() => {
                            // naviga/tor('/plans')
                        }}/>
                    </Animate>
                </div>
            </div>            
         <div className="countries">
            {countries
            .slice(0, mobile ? 5 : 
                showAll ? countries.length : max)
            .map((country: any, index: number) => (
                <Animate delay={index * 0.05} >
                <Country title={country.name} image={country.flagImageURL} 
                price={(country.priceOfCheapestPlan).toString()}
                 active={active === index} 
                clicked={() => {
                    // if (active === index) {
                    //     setActive(-1);
                    //     return;
                    // }
                    // setActive(index)
                    // handleShow();
                    navigate("/store/" + country.id);
                }} inner={
                    <div className="country__inner mt-4">
                        <div className="country__inner__title my-3">
                            <strong>{t("esim.section2.title")}</strong>
                        </div>
                        <div className="my-4">
                            <p>{t("esim.section2.sub_title")}</p>
                        </div>
                        <div className="d-flex mt-3">
                            <div className={i18n.language === "ar" ? "ms-4" : "me-4"}>
                            <a href="https://apps.apple.com/qa/app/tygo/id1619973559" target="_blank">
                            <img src="./media/buttons/apple.svg" width={144} />
                            </a>
                            </div>
                            <div className={i18n.language === "ar" ? "ms-4" : "me-4"}>
                            <a href="https://play.google.com/store/apps/details?id=app.tygo.sa" target="_blank">
                            <img src="./media/buttons/google.svg" width={144} />
                            </a>
                            </div>
                        </div>
                    </div>

                } courency={t("global.sar")} />
                </Animate>
            ))} 
        
        </div>
        <Animate delay={0.05} >
        {
            countries.length > max &&
            <div className="w-100 my-5 d-none d-md-block">
                <button className="btn d-block mx-auto show_more" onClick={() => {
                    if (showAll) {
                        setShowAll(false);
                        return;
                    }
                    setShowAll(true);
                }
                }>
                 <strong>{
                        showAll ? t("esim.section2.button_less") :
                 t("esim.section2.button") + " (" + countries.length+ ")"
                    } 
                  </strong>
                </button>
        </div>}
        </Animate>
       </div>
       <Modal show={show} onHide={handleClose}>
        <Modal.Body>
                        <Animate delay={0.05} >
                        <h1 className='mb-2 mt-2 mt-lg-3 mb-lg-5 text-center
                        '>{t("footer.title")}</h1>
                        </Animate>
                        <Animate delay={0.1} >
                        <p className='my-2 my-lg-5 text-center'>
                            {t("footer.sub_title")}
                        </p>
                        </Animate>
                        <Animate delay={0.2} >
                        <div className="d-flex justify-content-center">
          <div className='m-2'> 
          <a href="https://apps.apple.com/qa/app/tygo/id1619973559?platform=iphone](https://apps.apple.com/qa/app/tygo/id1619973559?platform=iphone)" target="_blank">
          <img src="/media/buttons/apple.svg" width={124} />
          </a>
          </div>
          <div className='m-2'>
          <a href='https://play.google.com/store/apps/details?id=app.tygo.sa' target="_blank">
          <img src="/media/buttons/google.svg" width={124} />
          </a>
          </div>
                </div>
                </Animate>
        </Modal.Body>
      </Modal>
       </div>
       
    );
};

    
const Country = (
    props: {
        title: string,
        image: string,
        price: string,
        courency: string,
        active: boolean,
        // onClick: any,
        inner: any,
        clicked: any,
    }
) => {
    const {t, i18n} = useTranslation();
    return (
        <div  className={`country ${props.active ? "country--active" : ""}`}>
            <div className={"country-wrapper" + (props.active ? " country-wrapper-active":"")}>
                <div className="country-card-front">

                <div className='d-flex d-md-block align-items-center'>
                <div className="country__image" style={{backgroundImage: `url(${props.image})`}}>
                    {/* <img src={props.image} className="flag" /> */}
                </div>
                <div className="title_subtitle">
                <div className="country__title">
                    <p className='m-0 my-2 bold'>{props.title}</p>
                </div>
                <div className="country__price">
                <p className={'m-0' + ((props.price)  ? '' : ' shine')}>
                        {
                            props.price &&
                                <>
                                <span className="text-muted">
                                {t("esim.section2.from")} </span> {props.price} {props.courency} 
                                </>
                        }
                        </p>
                </div>
                </div>
                </div>
                </div>
                    <div className="country-card-back">
                        <div className="country__inner">
                            
                                <strong className="text-center d-block">{t("Selecet your plan")}</strong>
                                {/* <div className=" my-2 my-lg-4">
                                    <Button inner={t("esim.section2.download")} color='rounded primary' isfullWidth={true} onClick={props.clicked} />
                                </div> */}
                                <div className=" my-2 my-lg-4">

                                <Button inner={t("See Plans")} color='rounded primary' isfullWidth={true} onClick={props.clicked} />
                                </div>
                            </div>
                    </div>

                <div className="inner">
                        {props.inner}
                    </div>
            </div>
        </div>
    );
};


    


export default Countries;