import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// import { LangContext } from '../../App';
// import component 👇
import Drawer from 'react-modern-drawer'
import Button from "../design/buttons/Button";
import { BsGlobe } from 'react-icons/bs';
//import styles 👇
import 'react-modern-drawer/dist/index.css'

import './header.css';
import { HiMenuAlt2, HiMenuAlt3 } from 'react-icons/hi';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
function Header() {
  
  // const lang = useContext(LangContext);
  const {t,i18n} = useTranslation();
  // const [open, setOpen] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false)
  const toggleDrawer = () => {
      setIsOpen((prevState) => !prevState)
  }

  return (
   <div className="row">
    <div className="col-12">
    <nav className="navbar navbar-expand-lg navbar-light" >
    <div className="d-flex justify-content-between w-100 align-items-center">
      <Link className="navbar-brand" to="/">
        <img src="/media/logos/default-dark.svg" alt="" width="86" height="40" className="d-inline-block align-text-top" />
      </Link>
      <span className='d-block d-lg-none' 
      onClick={() => {
        // setOpen(!open);
        toggleDrawer();
      }}
      >
        {
          i18n.language === 'en' ? <HiMenuAlt3 size={32} /> : <HiMenuAlt2 size={32} />
        }
      </span>
      <div className={"collapse navbar-collapse justify-content-between "}>
      <ul className="navbar-nav">
                      {
                        ELEMENTS.map((element, index) => {
                          return <li className={window.location.pathname === element.link ? 'nav-item active' : 'nav-item'} key={index}>
                        <Link className="nav-link" to={element.link}>{t(element.title)}</Link>
                      </li>}
                      )
                      }
                      
                    </ul>
          <div className="d-flex align-items-center">
            <div className='m-2'> 
            <a href="https://apps.apple.com/qa/app/tygo/id1619973559?platform=iphone](https://apps.apple.com/qa/app/tygo/id1619973559?platform=iphone)" target="_blank">
            <img src="/media/buttons/apple.svg" width={124} />
            </a>
            </div>
            <div className='m-2'>
            <a href='https://play.google.com/store/apps/details?id=app.tygo.sa' target="_blank">
            <img src="/media/buttons/google.svg" width={124} />
            </a>
            </div>
            <div className='mx-4'></div>
            <span style={{cursor: 'pointer'}}
                        onClick={() => {
                          const currentLang = localStorage.getItem('lang');
                          i18n.changeLanguage(currentLang === 'en' ? 'ar' : 'en');
                          localStorage.setItem('lang', currentLang === 'en' ? 'ar' : 'en');
                        }}
                      >
                      {t('header.language')}
                        
                      <div className='d-inline-block mx-2'>
                      <BsGlobe size={18} />
                      </div>
                      </span>
        </div>
      </div>
      </div>
                <Drawer
                    open={isOpen}
                    onClose={toggleDrawer}
                    direction={i18n.language === 'en' ? 'left' : 'right'}
                    className='p-4'
                    size={330}
                    zIndex={1000}
                >
                    <div className='drawer-header'>
                        <Button color="circle" 
                        isfullWidth={false} onClick={toggleDrawer} inner={
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.50977 18.4898L18.4898 1.50977" stroke="#2F1952" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M18.4898 18.4898L1.50977 1.50977" stroke="#2F1952" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        } />
                        <img src="/media/logos/default-dark.svg" alt="" width="70" height="32" className="d-inline-block align-text-top my-4" />
                    </div>
                    
                    <div className='drawer-content'>
                      <ul className="navbar-nav">
                        {
                          ELEMENTS.map((element, index) => {
                            return <li className={window.location.pathname === element.link ? 'nav-item active' : 'nav-item'} key={index}>
                          <Link className="nav-link" to={element.link}>{t(element.title)}</Link>
                          {i18n.language === 'en' ? <IoIosArrowForward size={14} /> : <IoIosArrowBack size={14} />}
                        </li>}
                        )
                        }
                        <li className="nav-item mt-4"
                          onClick={() => {
                            const currentLang = localStorage.getItem('lang');
                            i18n.changeLanguage(currentLang === 'en' ? 'ar' : 'en');
                            localStorage.setItem('lang', currentLang === 'en' ? 'ar' : 'en');

                          }}
                        >
                        {t('header.language')}
                        </li>
                      </ul>
                    </div>
                    <div className='row mt-3'>
                      <div className='col-6'>
                        <a href="https://apps.apple.com/qa/app/tygo/id1619973559?platform=iphone](https://apps.apple.com/qa/app/tygo/id1619973559?platform=iphone)" target="_blank">
                        <img src="/media/buttons/apple.svg" width={124} />
                        </a>
                      </div>
                      <div className='col-6'>
                        <a href='https://play.google.com/store/apps/details?id=app.tygo.sa' target="_blank">
                        <img src="/media/buttons/google.svg" width={124} />
                        </a>
                      </div>
                    </div>

                </Drawer>
    </nav>
    </div>
   </div>
  );
}

const ELEMENTS = [
  {
    title: 'header.home',
    link: '/'
  },
  {
    title: 'header.plans',
    link: '/plans'
  },
  {
    title: 'header.esim_for_traveling',
    link: '/esims'
  },
  {
    title: 'header.about',
    link: '/about'
  },
  // {
  //   title: 'header.professionals',
  //   link: '/professionals'
  // }
  // {
  //   title: 'header.services',
  //   link: '/services'
  // },
  // {
  //   title: 'header.comparing',
  //   link: '/compare'
  // },
  // {
  //   title: 'header.help_center',
  //   link: '/support'
  // },
]

export default Header;