import { useTranslation } from 'react-i18next';
import './hero.css'
import parse from 'html-react-parser'
import { useState } from 'react';
import Animate from '../../about/Animate';

const Hero = ({countries}: any) => {
    const {t, i18n} = useTranslation();
   

    return (
        <Animate
        // 300 delay
        // ease-out-back
delay={0.1}
>
        <div className="hero">
            <div className="row">
                <div className="col-12 col-md-6 order-2 order-md-1">
                    <Animate delay={0.2}>
                    <div className={"d-flex flex-column justify-content-center " + (i18n.language === 'en' ? "  pe-md-5 me-md-5" : " ps-md-5 ms-md-5")} style={{
                        minHeight: "464px"
                    }}>
                        <Animate delay={0.3}>
                        <p className="tag mt-5 c-primary mx-auto mx-md-0 my-3">{t("esim.section1.tag")}</p>
                        </Animate>
                        <Animate delay={0.4}>
                        <h1 className="hero__title ">{parse(t("esim.section1.title"))}</h1>
                        </Animate>
                        <Animate delay={0.5}>
                        <p className="hero__title mb-5">{parse(t("esim.section1.sub_title"))}</p>
                        </Animate>
                        {/* <div className='d-flex flex-column flex-md-row justify-content-center justify-content-md-start my-4 text-center text-md-start'> */}
                            {/* <div className={"btn-text-hero" + (i18n.language === 'en' ? "  me-md-3" : " me-md-3")}>
                                <strong className="">{t("esim.section1.country")}</strong>
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={{transform: i18n.language === 'en' ? "scale(-0.7)" : 'scale(0.7)'}}>
                            <path d="M15.95 31.3669C16.2666 31.3669 16.5833 31.2502 16.8333 31.0002C17.3166 30.5169 17.3166 29.7169 16.8333 29.2335L7.59997 20.0002L16.8333 10.7669C17.3166 10.2835 17.3166 9.48353 16.8333 9.0002C16.35 8.51686 15.55 8.51686 15.0666 9.0002L4.94996 19.1169C4.46663 19.6002 4.46663 20.4002 4.94996 20.8835L15.0666 31.0002C15.3166 31.2502 15.6333 31.3669 15.95 31.3669Z" fill="#3eb6ae"></path><path d="M6.1165 21.25H34.1665C34.8498 21.25 35.4165 20.6833 35.4165 20C35.4165 19.3167 34.8498 18.75 34.1665 18.75H6.1165C5.43317 18.75 4.8665 19.3167 4.8665 20C4.8665 20.6833 5.43317 21.25 6.1165 21.25Z" fill="#3eb6ae"></path></svg>
                            </div> */}
                            {/* <div className={"btn-text-hero"  + (i18n.language === 'en' ? "  me-mdd-3" : " me-mdd-3")}>
                                <strong className="">{t("esim.section1.install")}</strong>
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={{transform: i18n.language === 'en' ? "scale(-0.7)" : "scale(0.7)"}}><path d="M15.95 31.3669C16.2666 31.3669 16.5833 31.2502 16.8333 31.0002C17.3166 30.5169 17.3166 29.7169 16.8333 29.2335L7.59997 20.0002L16.8333 10.7669C17.3166 10.2835 17.3166 9.48353 16.8333 9.0002C16.35 8.51686 15.55 8.51686 15.0666 9.0002L4.94996 19.1169C4.46663 19.6002 4.46663 20.4002 4.94996 20.8835L15.0666 31.0002C15.3166 31.2502 15.6333 31.3669 15.95 31.3669Z" fill="#3eb6ae"></path><path d="M6.1165 21.25H34.1665C34.8498 21.25 35.4165 20.6833 35.4165 20C35.4165 19.3167 34.8498 18.75 34.1665 18.75H6.1165C5.43317 18.75 4.8665 19.3167 4.8665 20C4.8665 20.6833 5.43317 21.25 6.1165 21.25Z" fill="#3eb6ae"></path></svg>
                            </div>
                        </div> */}
                        
                    </div>
                    </Animate>
                </div>
                <div className="col-12 col-md-6 position-relative order-1 order-md-2" style={{minHeight: "300px", maxHeight: "500px"}}>
                    {/* <div className="top_gradient" style={{background: "linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)", height: "200px", position: "absolute", top: "0", width: "calc(100% - 24px)"}}/> */}
                        <div className="images_bg" style={{backgroundImage: "url(./media/hero/hero.png)"}}/>
                    {/* <div className="button_gradient" style={{background: "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)", height: "200px", position: "absolute", bottom: "0", width: "calc(100% - 24px)"}}/> */}
                </div>
            </div>
            

            
        </div>
        </Animate>
    )
}

export default Hero