




import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/design/buttons/Button';
import './planHeader.css';
// MdOutlineFileUpload
import { MdOutlineFileUpload } from 'react-icons/md';
// HiOutlineThumbUp
import { HiOutlineThumbUp, HiOutlineUpload } from 'react-icons/hi';
// sim card
import { TbDeviceSim } from 'react-icons/tb';

const PlanHeader = ({plan,share}: {plan: any,share:any}) => {
    const {t, i18n} = useTranslation();

    return (
        
        <div className={`plan-header w-100`}>
            <div className="d-lg-flex align-items-center justify-content-between w-100" style={{minHeight: "132px"}}>
                {/*     justify-content: space-between; */}
            <div className="d-flex align-items-center mb-3 w-100 align-items-lg-start">
                <img className="image" src={plan.image} alt={plan.name} />
                <div className="px-3 w-100 d-flex flex-row justify-space-between h-100 ">
                    <div className="w-100 d-flex flex-column justify-content-between">
                        <strong className=' title_header_d'>{plan.title}</strong>

                        {
                            plan.hasDiscount ? 
                            <div style={{
                                display: 'flex',
                                gap: '6px',
                                flexDirection: 'row',
                                width: 'max-content',
                            }}>
                                <strong className='' style={{textDecoration: 'line-through'}}>{plan.price}</strong> | <strong className='price' style={{fontSize: "1rem"}}>{plan.newPrice} {t("global.mini_sar")}</strong>
                            </div>
                            : <strong className='price' style={{fontSize: "1rem"}}>{plan.newPrice}</strong>
                        }
                    </div>
                    <div className='d-none d-lg-flex align-items-center justify-content-between w-100'>
                        <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center ">
                            {/* sim */}
                            {/* <i className="fas fa-sim-card"></i> */}
                            {/* <div className='cardsim-bg'>
                                <TbDeviceSim style={{fontSize: "1.5rem"}} />
                            </div>
                            <div className='sec'>
                                <strong>{plan.providerName}</strong>
                                <p className="text-muted">{t("details.provider")}</p>
                            </div> */}
                        </div>
                        <div className="d-flex align-items-center">
                            {/* Internent */}
                            {/* <div className='cardsim-bg'>
                                <TbDeviceSim style={{fontSize: "1.5rem"}} />
                            </div> */}
                            {/* <i className="fas fa-sim-card"></i> */}
                            {/* <div className='sec'>
                                <strong>{plan.typeName}</strong>
                                <p className="text-muted">{t("details.type")}</p>
                            </div> */}
                        </div>
                        </div>
                        <div className="row mb-3" style={{minWidth: "377px"}}>
                            <div className="col-6">
                            {/* <Button inner={
                                <div className="d-flex align-items-center justify-content-center" style={{width: "max-content"}}>
                                    <HiOutlineThumbUp style={{fontSize: "1rem"}} />
                                    <p className="mx-3 my-0" style={{fontSize: "0.8rem"}}
                                    >{t("details.like") + " ( " + plan.likes + " " + 
                                    " )"}</p>
                                </div>

                            } onClick={function (): void {
                                    throw new Error('Function not implemented.');
                                } } color="outline" isfullWidth={true} /> */}

                            </div>
                            <div className="col-6">
                            <Button inner=
                            {
                                <div className="d-flex align-items-center justify-content-center">
                                    {/* <i className="fas fa-share-alt"></i> */}
                                    {/* <HiOutlineUpload style={{fontSize: "1rem"}} />
                                     */}
                                     <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.8335 9.16536L17.6668 2.33203" stroke="#2F1952" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.3335 5.66797V1.66797H14.3335" stroke="#2F1952" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.1665 1.66797H7.49984C3.33317 1.66797 1.6665 3.33464 1.6665 7.5013V12.5013C1.6665 16.668 3.33317 18.3346 7.49984 18.3346H12.4998C16.6665 18.3346 18.3332 16.668 18.3332 12.5013V10.8346" stroke="#2F1952" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                                    <p className="mx-3 my-0" style={{fontSize: "0.8rem"}}
                                    >{t("details.share")}</p>
                                </div>
                            } onClick={share } color="outline" isfullWidth={true} />
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
                    <div className='d-lg-none d-flex flex-row my-3'>
                        <div className="d-flex align-items-center ">
                            {/* sim */}
                            {/* <i className="fas fa-sim-card"></i> */}
                            <div className='cardsim-bg'>
                                <TbDeviceSim style={{fontSize: "1.5rem"}} />
                            </div>
                            <div className='sec'>
                                <strong>{plan.providerName}</strong>
                                <p className="text-muted">{t("details.provider")}</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            {/* Internent */}
                            <div className='cardsim-bg'>
                                <TbDeviceSim style={{fontSize: "1.5rem"}} />
                            </div>
                            {/* <i className="fas fa-sim-card"></i> */}
                            <div className='sec'>
                                <strong>{plan.typeName}</strong>
                                <p className="text-muted">{t("details.type")}</p>
                            </div>
                        </div>
                    </div>
            <div className="row mb-3 d-lg-none">
                <div className="col-6">
                {/* <Button inner={
                    <div className="d-flex align-items-center justify-content-center" style={{width: "max-content"}}>
                        <HiOutlineThumbUp style={{fontSize: "1.5rem"}} />
                        <p className="mx-3 my-0">{t("details.like") + " ( " + plan.likes + " " + 
                          " )"}</p>
                    </div>

                } onClick={function (): void {
                        throw new Error('Function not implemented.');
                    } } color="outline" isfullWidth={true} /> */}

                </div>
                <div className="col-6">
                <Button inner=
                {
                    <div className="d-flex align-items-center justify-content-center">
                        {/* <i className="fas fa-share-alt"></i> */}
                        <HiOutlineUpload style={{fontSize: "1.5rem"}} />
                        <p className="mx-3 my-0">{t("details.share")}</p>
                    </div>
                } onClick={share} color="outline" isfullWidth={true} />
                </div>
            </div>
            {/* <p className="text-muted">{plan.type}</p> */}
            </div>
            

        
        
                
        </div>
    );
}


export default PlanHeader;