import React from 'react';
import './OrderItem.css'
import Button from '../../design/buttons/Button';
import { useTranslation } from 'react-i18next';

const OrderItem = ({ plan, onClick, button }: any) => {
    const { id, title, price, description, features } = plan;
    const {t,i18n} = useTranslation();

    return (
        <div className='p-4 w-100 order-item_chassy'>
        <span className='mb-2 d-block'>Order Confirmation: Thank you for your Recent purchase!</span>
        <div className="order-item">
            <div className="">
            <div>
            <span className='title_' style={{lineHeight: '.7'}}>{title}</span><br />
            <span className='price_item'> {price} {t('global.sar')}</span>
            </div>
            <div className="d-flex gap-2 mt-2 mb-3">
            <Info name={"store.validity"} logo="/media/svgs/clock_.svg" info={"7 Days"} />
            <Info name={"store.network"} logo="/media/svgs/rss.svg" info={"Zain , orange"} />
            </div> 
            
            </div>
            {onClick && 
            typeof button === 'string' ?
            <Button inner={t(button)} isfullWidth={true} onClick={() => onClick(id)} color="primary px-4 buy_btn" />
            :
            button
            }
        </div>
        </div>
    );
    };

const Info = ({ name, logo, info}: any) => {
    const {t,i18n} = useTranslation();
    return (
        <div className="info">
            <div className="d-flex" style={{ alignItems: 'center', gap: 10 }}>
                <img src={logo} alt={name} width={46} height={46} className="info-logo" />
                <div className="d-flex flex-column">
                    <span className='text-muted'>{name}</span>
                    <span>{info}</span>
                </div>
            </div>
        </div>
    );
    };


export default OrderItem;

export { Info };
    