//progress bar


const ProgressBar = ({currentStep, steps}: {currentStep: number, steps: number}) => {
    const progress = (currentStep / steps) * 100;
    return (
        <div style={{width: "100%", height: "5px", backgroundColor: "white"}}>
            <div style={{width: `${progress}%`, height: "5px", backgroundColor: "#3db6ae"}}></div>
        </div>
    );
};



export default ProgressBar;