




import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CompareCardContext } from '../../../components/compare/CompareCard';
import Dragable from '../../../components/compare/Dragable';
import Button from '../../../components/design/buttons/Button';
import './planBar.css';

import { HiOutlineGlobeAlt } from "react-icons/hi";
import { TbCash } from "react-icons/tb";
import { TbPhone } from "react-icons/tb";


const PlanBar = ({plan}: any) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const {addToCart} = useContext(CompareCardContext);

    return (
        <Dragable plan={plan}>
            <div className={`plan-bar w-100 mx-0  row ${plan.isActive ? 'active' : ''}`} id={"plan" + plan.id}>
            {
                plan.isActive && <div className={(i18n.language == 'ar' ? 'recommendedar' : 'recommended') + ' col-xl-4'}>
                {t('recommended_plans.recommended')}
                </div>
            }
            <div className='col-xl-8'>
            <div className="d-block d-lg-flex align-items-center">
            <div className="d-flex align-items-center" style={{minWidth: "320px"}}>
                <img className="image mx-md-0 my-4" src={plan.image} alt={plan.name} />
                <div className={"sec mx-0 mx-lg-3 "  + (i18n.language == 'ar' ? 'pe-1 pe-lg-3' : 'ps-1 ps-lg-3')}>
                    <strong className='text-center d-block'>{plan.title}</strong>
                    <p className="text-muted">{plan.planTypeName}</p>
                    {/* <strong className='price mt-2 d-none d-lg-block'>{plan.price} {t("global.mini_sar")}</strong> */}
                </div>
            </div>
            <div className="d-flex w-100 justify-content-between align-items-center" >
            <div className="d-flex align-items-center">
                {/* messages */}
                {/* <i className="fas fa-envelope-open-text"></i> */}
                <TbPhone size={24} />
                <div className={'sec ' + (i18n.language == 'ar' ? 'pe-1 pe-lg-3' : 'ps-1 ps-lg-3')}>
                    <span>{plan.calls == -1 ? t("global.unlimited") : plan.calls+" "+t('plans.minutes')}</span>
                    <p className="text-muted d-none d-lg-block">{t('recommended_plans.calls')}</p>
                </div>
            </div>
            <div className="d-flex align-items-center">
                {/* Internent */}
                {/* <i className="fas fa-wifi"></i> */}
                <HiOutlineGlobeAlt size={24} />
                <div className={'sec ' + (i18n.language == 'ar' ? 'pe-1 pe-lg-3' : 'ps-1 ps-lg-3')}>
                    <span>{plan.internet == -1 ? t("global.unlimited") : plan.internet/1000+" "+t('plans.gb')}</span>
                    <p className="text-muted d-none d-lg-block">{t('recommended_plans.internet')}</p>
                </div>
            </div>
            <div className="d-flex align-items-center">
                {/* price */}
                {/* <i className="fas fa-dollar-sign"></i> */}
                <TbCash size={34} />
                <div className={'sec ' + (i18n.language == 'ar' ? 'pe-1' : 'ps-1')}>
                    <strong className='price'>{plan.price} {t("global.mini_sar")}</strong>
                    <p className="text-muted d-none d-lg-block">{t('recommended_plans.price')}</p>
                </div>
            </div>
            </div>
            </div>
            </div>

            <div className='col-xl-4 mt-3 mt-xl-0' style={{maxWidth: "300px"}}>
                <div className="row">
                    <div className="col-6 px-2">

                <Button inner={t('recommended_plans.details')}
                 onClick={
                    () => navigate(`/plans/${plan.id}` )
                    // () => setSelectedPlan(plan)
                } color="primary" isfullWidth={true} isrounded={true}/>
                    </div>
                    <div className="col-6 px-2">
                <Button inner={t('recommended_plans.compare')}
                 onClick={function (): void {
                    addToCart(plan, 'plan'+plan.id);
                } } color="secondary" isfullWidth={true} isrounded={true}/>
                    </div>
                </div>
            </div>
        </div>
        </Dragable>
    );
}


export default PlanBar;