import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Button from '../../../components/design/buttons/Button';
import { useTranslation } from 'react-i18next';
import Animate from '../../about/Animate';

export default function Slider(
    props: {
        inners: any[][],
        seperator: any,
    }
) {
    const [index, setIndex] = React.useState(0);
    const [active, setActive] = React.useState(0);
    const [mobile, setMobile] = React.useState(false);
    const {t, i18n} = useTranslation();
  const handleSelect = (selectedIndex: any, e: any) => {
    setIndex(selectedIndex);
  };

    React.useEffect(() => { 
        if (window.innerWidth < 992) {
            setMobile(true);
        }
    }, [])


    return (
            <Animate delay={0.1}>
            <div className='my-5 py-5'>
                <div className='row'>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
                        <Animate delay={0.2}>
                        <div className="row justify-content-between my-4" style={{maxWidth: "520px"}}>
                        <div className='col-6'>
                        <Button inner={t("esim.section5.ios")}
                        onClick={
                            () => {
                                setActive(0)
                            }
                        } color={active === 0 ? "primary" : "secondary"}
                        isfullWidth={true} isrounded={true}/>  
                        </div>
                            <div className='col-6'> 
                        <Button inner={t("esim.section5.android")}
                                                    onClick={
                            () => {
                                setActive(1)
                            }
                        } color={active === 1 ? "primary" : "secondary"}
                        isfullWidth={true} isrounded={true}/>
                        </div>
                        </div>
                        </Animate>
                        <Animate delay={0.3}>
                        <Carousel activeIndex={index} onSelect={handleSelect} controls={false} interval={null} indicators={false}>
                        {/* {props.inners[active].map((inner, index) => {
                            return (
                                <Carousel.Item>
                                    </Carousel.Item>
                            )
                        })} */}
                        <Animate delay={0.4}>
                        <h1 style={{minHeight: "120px",     fontSize: "58px"}} className='text-center text-md-start title_how_to'
                        >{props.inners[active][0].title}</h1>
                        </Animate>                                   
                        </Carousel>
                        </Animate>
                        <div className='d-block d-md-none'>
                        <Carousel activeIndex={index} onSelect={handleSelect} interval={null} controls={false} indicators={false}>
                        {props.inners[active].map((inner, index) => {
                            return (
                                
                                <Carousel.Item>
                                    <img src={inner.image_mobile} 
                                     height={"444px"} style={{objectFit: "contain"}} className="d-block w-100 my-4" alt="..."/>
                                     {/* {inner.image} */}
                                     {/* './media/slider/1.png' */}
                                </Carousel.Item>
                            )
                        })}
                    </Carousel>
                        </div>
                        <strong className='d-none d-md-block'>
                            <h1>0{index+1}</h1> / 0{props.inners[active].length}
                        </strong>
                        <strong className='d-block d-md-none'>
                            <span>0{index+1} / 0{props.inners[active].length} </span>
                        </strong>
                        <Animate delay={0.5}>
                        <div className='d-flex my-4 justify-content-center justify-content-md-start'>
                            {Array.from({length: props.inners[0].length}).map((_, index_) => {
                                return (
                                        <div className='me-3' onClick={() => setIndex(index_)} style={{cursor: "pointer", width: "32px", height: "6px", borderRadius: "3px", backgroundColor: index === index_ ? "#3EB6AE" : "#DADADA"}}></div>
                                )
                            })}
                        </div>    
                        </Animate>     
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-none d-md-block'>
                        <Animate delay={0.6}>
                    <Carousel activeIndex={index} onSelect={handleSelect} interval={null} controls={false} indicators={false}>
                        {props.inners[active].map((inner, index) => {
                            return (
                                <Carousel.Item>
                                    {/* <img src='./media/slider/1.png' width='100%' /> */}
                                    <img src={inner.image}
                                     width='100%' />
                                </Carousel.Item>
                            )
                        })}
                    </Carousel>
                    </Animate>
                    </div>
                </div>
            </div>
            </Animate>
    )

}