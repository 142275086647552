
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import './Store.css';
import React, { useState } from 'react';
import {  getPlans } from '../../services/plans.service';
import { useTranslation } from 'react-i18next';
import PlanItem, { Info } from './components/PlanItem';
import Button, { ButtonSquare } from '../../components/design/buttons/Button';
import {  HiOutlineChip, HiOutlineDocument } from 'react-icons/hi';
import SideInfo from './components/sideInfo';
import { getCountryById, getProviderById } from '../../services/providers.service';
import Payment, { CardInfo } from './components/Payment';
import { Modal } from 'react-bootstrap';
import { Advantages } from '../esims/components/advantages';
import Faq, { Questions } from '../esims/components/faq';
import { Slider } from '../order/Order';
import {ShimmerTitle} from 'react-shimmer-effects-18';

const Store = () => {
    const navigate = useNavigate();
    const id = useParams().id;
    const itemId = useParams().item;
    const [page, setPage] = React.useState<any>(1);
    const [plans, setPlans] = React.useState<any>([]);
    const [country, setCountry] = React.useState<any>(null);
    const {t,i18n} = useTranslation();
    const [plan, setPlan] = React.useState<any>(null);
    const [loading, setLoading] = React.useState<any>(true);
    const updatePlans = (
        {
            page,
            country,
        }: any = {
            page: 1,
            country: id,
        }
    ) => {
        getPlans({
            countryId: country,
            offset: 1,
            limit: 6,
        }, i18n.language).then((dat: any) => {
            Promise.all(dat.returnData.map((plan: any) => {
                return getProviderById(plan.providerId, i18n.language).then((provider: any) => {
                    return {
                        ...plan,
                        provider: provider.returnData,
                    };
                });
            })).then((plansWithProviders: any) => {
                setPlans(plansWithProviders);
                setLoading(false);
            });
        })
    }

    const getCountry = () => {
        getCountryById(+id!, i18n.language).then((dat: any) => {
            setCountry(dat.returnData);
        });
    }

    //payment
    const [selected, setSelected] = React.useState(0);
    const [show, setShow] = React.useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    React.useEffect(() => {
        if (id) {
            getCountry();
        }
        updatePlans();
    }
    , [id, page, i18n.language]);

    const updatePlan = async (
        {
            planId
        }: any 
    ) => {
        getPlans({
            planId,
        }, i18n.language).then(async (dat: any) => {
            const provider = await getProviderById(dat.returnData[0].providerId, i18n.language);
            setPlan({
                ...dat.returnData[0],
                provider,
            });
        });
        


    }


    React.useEffect(() => {
        if (itemId && plans.length > 0 && plans.find((plan: any) => +plan.id === +itemId)) {
            setPlan(plans.find((plan: any) => +plan.id === +itemId));
        }
    }, [itemId, plans]);
    return (
        <>
                <div style={{backgroundImage: "url(/media/hero/bg2.png)", backgroundRepeat:"no-repeat", backgroundSize: "cover"}}>

        <div className="container">
            <Header />
            <div className="row store mt-3 mt-lg-5" style={{minHeight: "80vh"}}>
                <div className="col-lg-8 col-sm-12 col-xs-12 ">
                    {/* back button */}
                    
                    {/* header */}
                    
                    {/* plans */}
                    {
                        !itemId ?
                    <>
                    {
                        country ?
                        <CountryHeader country={country} />
                        : <ShimmerTitle line={2} gap={10} variant="primary" />
                    }
                    {
                        <div className="d-block d-lg-none mt-0 mt-lg-5">

                            <SideInfo 
                            background={
                                country?.countryImageURL}
                                //  country?.countryImageURL
                                //     :
                                // "https://images.unsplash.com/photo-1491884662610-dfcd28f30cfb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2624&q=80"
                            // }
                            is_bg={country?.countryImageURL? true : false}
                            elements={plans.map((plan_: any) => {
                                return <PlanItem plan={plan_} onClick={() => navigate(`/store/${id}/${plan_.id}`)} className={"p-1 mb-0"}/>
                                
                            }
                            )}
                            noBg={[]}
                            />
                        </div>
                    }
                    {
                        <div className="d-none d-lg-block mt-0 mt-lg-5">
                            <div className="row">
                            {
                                loading?
                                [0,1,2,3,4,5].map((item: number) => (
                                    <div className="col-lg-4 col-sm-12 col-xs-12">
                                    <div className="plan-item" key={item}>
                                        <ShimmerTitle line={6} gap={10} variant="primary" />
                                    </div>
                                    </div>
                                ))
                                :
                                plans.map((plan_: any) => {
                                    return (
                                        <>
                                        <div className="col-lg-4 col-sm-12 col-xs-12">
                                            <PlanItem plan={plan_} onClick={() => navigate(`/store/${id}/${plan_.id}`)} />
                                        </div>  
                                        </>
                                    )
                                }
                                )
                            }
                            
                            </div>
                        </div>
                    }
                        </>
                        :
                        <div className=" d-lg-block">
                            {/* <Payment /> */}
                        </div>
                    }
                    {/* pagination */}
                    {/* <div className="pagination">
                        <div className="pagination-item" onClick={() => setPage(page - 1)}>Prev</div>
                        <div className="pagination-item" onClick={() => setPage(page + 1)}>Next</div>
                    </div> */}
                    
                </div>
                <div className="col-lg-4 col-sm-12 col-xs-12">
                    <div className="row">
                        {
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 d-none d-lg-block">
                            <div className="country-img" style={{
                                backgroundPosition: "center",
                                backgroundSize: country?.countryImageURL? "": "auto 60%",
                                backgroundRepeat: "no-repeat",
                                backgroundColor: "#3db6ae",
                                backgroundImage: 
                            ("url("+(country?.countryImageURL? country?.countryImageURL:"https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/Antu_nmap.svg/1920px-Antu_nmap.svg.png")+")")}}></div>     
                        </div>
                        }
                    </div>
                </div>
            </div>

        </div>
        </div>
        <Footer />
        </>
    );
    }

const CountryHeader = ({country ,plan_id = 1,}: any) => {
    const navigate = useNavigate();

    const [show_1   , setShow_1] = useState(false);
    const [show_2   , setShow_2] = useState(false);
    
    const handleShow1 = () => setShow_1(true);
    const handleShow2 = () => setShow_2(true);

    const handleClose1 = () => setShow_1(false);
    const handleClose2 = () => setShow_2(false);
    

    const {t,i18n} = useTranslation();
    return (
        <div className="">
                    <Button color="icon d-none d-lg-block"   isfullWidth={false} onClick={()=>{
                        navigate(-1);
                    }} inner={
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={{transform: i18n.language === "ar" ? "rotate(180deg)" : ""}}>
                    <path d="M15.95 31.3669C16.2666 31.3669 16.5833 31.2502 16.8333 31.0002C17.3166 30.5169 17.3166 29.7169 16.8333 29.2335L7.59997 20.0002L16.8333 10.7669C17.3166 10.2835 17.3166 9.48353 16.8333 9.0002C16.35 8.51686 15.55 8.51686 15.0666 9.0002L4.94996 19.1169C4.46663 19.6002 4.46663 20.4002 4.94996 20.8835L15.0666 31.0002C15.3166 31.2502 15.6333 31.3669 15.95 31.3669Z" fill="#292D32"/>
                    <path d="M6.1165 21.25H34.1665C34.8498 21.25 35.4165 20.6833 35.4165 20C35.4165 19.3167 34.8498 18.75 34.1665 18.75H6.1165C5.43317 18.75 4.8665 19.3167 4.8665 20C4.8665 20.6833 5.43317 21.25 6.1165 21.25Z" fill="#292D32"/>
                    </svg>
                    } />
        <div className="row align-items-center justify-content-between">
        <div className="col-lg-auto col-md-auto col-sm-12 col-xs-12">

            <h1 className="country-title mt-4 m-lg-0 d-flex align-items-center">
            <div onClick={()=>{
                navigate(-1);
            }} style={{cursor: "pointer"}}>
            <svg className=' d-lg-none' width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={{transform: (i18n.language === "ar" ? "scale(-0.6)" : "scale(0.6)")}}>
                    <path d="M15.95 31.3669C16.2666 31.3669 16.5833 31.2502 16.8333 31.0002C17.3166 30.5169 17.3166 29.7169 16.8333 29.2335L7.59997 20.0002L16.8333 10.7669C17.3166 10.2835 17.3166 9.48353 16.8333 9.0002C16.35 8.51686 15.55 8.51686 15.0666 9.0002L4.94996 19.1169C4.46663 19.6002 4.46663 20.4002 4.94996 20.8835L15.0666 31.0002C15.3166 31.2502 15.6333 31.3669 15.95 31.3669Z" fill="#292D32"/>
                    <path d="M6.1165 21.25H34.1665C34.8498 21.25 35.4165 20.6833 35.4165 20C35.4165 19.3167 34.8498 18.75 34.1665 18.75H6.1165C5.43317 18.75 4.8665 19.3167 4.8665 20C4.8665 20.6833 5.43317 21.25 6.1165 21.25Z" fill="#292D32"/>
                    </svg>
            </div>
                {country.name}</h1>
            </div>
            <div className="col-lg-auto col-md-auto col-sm-12 col-xs-12">
            <div className='d-flex gap-3 my-3'>
            {/* <Button inner={<div className="d-flex align-items-center justify-content-center" style={{width: "max-content"}}>
                    <HiOutlineDocument style={{fontSize: "1rem"}} />
                    <p className="mx-3 my-0" style={{fontSize: "0.8rem"}}>{t("store.plan_details")}</p>
            </div>} onClick={handleShow1} 
            color="outline" isfullWidth={true} /> */}
            <Button inner={
                <div className="d-flex align-items-center justify-content-center" style={{width: "max-content"}}>
                    <HiOutlineChip style={{fontSize: "1rem"}} />
                    <p className="mx-3 my-0" style={{fontSize: "0.8rem"}}
                    >{t("store.about_esim")}</p>
                </div>
            } onClick={handleShow2} color="outline" isfullWidth={true} />
            </div>
            </div>
        </div>
        <Modal show={show_1} onHide={handleClose1} centered size="lg" className="modal_store_info">
        <Modal.Header style={{
            border: "none"
        }} closeButton>
        </Modal.Header>
        <Modal.Body style={{
            textAlign: i18n.language === "ar" ? "right" : "left"
        }}>
            <div style={{height:128, width:128, background:" #3EB6AE",display:"flex",alignItems:"center",justifyContent:"center", borderRadius: "50%", margin: "0 auto 3rem auto"}}>
             <HiOutlineDocument style={{fontSize: "3.5rem", color: "#fff"}} />
            </div>
            <h4 className='my-4'>{t("store.details.title")}</h4>
            <h5>{t("store.details.sections.section_1.title")}</h5>
            <p>{t("store.details.sections.section_1.description")}</p>

            <h5>{t("store.details.sections.section_2.title")}</h5>
            <p>{t("store.details.sections.section_2.description")}</p>

            <h5>{t("store.details.sections.section_3.title")}</h5>
            <p>{t("store.details.sections.section_3.description")}</p>

            <h5>{t("store.details.sections.section_4.title")}</h5>
            <p>{t("store.details.sections.section_4.description")}</p>

            <h5>{t("store.details.sections.section_5.title")}</h5>
            <p>{t("store.details.sections.section_5.description")}</p>

            <h5>{t("store.details.sections.section_6.title")}</h5>
            <p>{t("store.details.sections.section_6.description")}</p>

            <Button inner={t("store.details.close")+""}color="primary mt-4" isfullWidth onClick={handleClose1} />
        </Modal.Body>
        </Modal>
        {/* <Modal show={show_2} onHide={handleClose2} centered size="xl" className="modal_store_info">
        <Modal.Header style={{
            border: "none"
        }} closeButton>
        </Modal.Header>
        <Modal.Body style={{
            textAlign: i18n.language === "ar" ? "right" : "left"
        }}>
            <div style={{height:128, width:128, background:" #3EB6AE",display:"flex",alignItems:"center",justifyContent:"center", borderRadius: "50%", margin: "0 auto 3rem auto"}}>
             <HiOutlineChip style={{fontSize: "3.5rem", color: "#fff"}} />
            </div>
            <h4 className='my-4'>{t("store.about.title")}</h4>
            <h5>{t("store.about.sections.section_1.title")}</h5>
            <p>{t("store.about.sections.section_1.description")}</p>

            <h5>{t("store.about.sections.section_2.title")}</h5>
            <p>{t("store.about.sections.section_2.description")}</p>

            <h5>{t("store.about.sections.section_3.title")}</h5>
            <p>{t("store.about.sections.section_3.description")}</p>

            <h5>{t("store.about.sections.section_4.title")}</h5>
            <p>{t("store.about.sections.section_4.description")}</p>
            <Button inner={t("store.about.close")+""}color="primary mt-4" isfullWidth onClick={handleClose2} />
        </Modal.Body>
        </Modal> */}
        <Modal show={show_2} onHide={handleClose2} centered size="xl" className="modal_store_info">
        <Modal.Header style={{
            border: "none",
            position: "fixed",
            zIndex: 10,
            background: "#fff",
            borderRadius: "50%",
        }} closeButton>
        </Modal.Header>
        <Modal.Body>
            <PlanDetails />
                        <Button inner={t("store.about.close")+""}color="primary mt-4" isfullWidth onClick={handleClose2} />

        </Modal.Body>
       </Modal>
        

    </div>
            
    );
    }

const PlanDetails = () => {
    const {t,i18n} = useTranslation();
    
    return(
        <div style={{
            // textAlign: i18n.language === "ar" ? "right" : "left"
            direction: i18n.language === "ar" ? "rtl" : "ltr"
        }}>
            <Advantages hasDescription/>
            <div className='p-4 ' style={{background:"#1D1B33", borderRadius: "22px", position: "relative"}}>
            <h4 className='my-4 text-center' style={{color:"white"}}>{t("esim.section1.install")}</h4>
            <div className='bg_how'>
            <svg width="376" height="375" viewBox="0 0 376 375" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M178.537 143.543L178.539 143.543C195.685 139.88 213.479 145.683 225.17 158.748L225.172 158.75C236.917 171.768 240.789 190.079 235.319 206.737L235.318 206.739C229.917 223.419 215.995 235.928 198.834 239.52L198.832 239.52C181.686 243.183 163.892 237.381 152.201 224.315L152.2 224.313C140.455 211.296 136.583 192.984 142.052 176.327L142.053 176.325C147.454 159.645 161.376 147.135 178.537 143.543Z" fill="url(#paint0_linear_1975_3245)" stroke="url(#paint1_linear_1975_3245)"/>
<path opacity="0.2" d="M257.58 125.537V125.537C235.429 100.781 201.715 89.7881 169.229 96.7273V96.7273" stroke="#EB5BE5" stroke-width="6"/>
<path opacity="0.04" d="M169.455 99.5333L169.467 99.5308C200.9 92.8164 233.522 103.454 254.955 127.407L254.964 127.416C276.495 151.281 283.594 184.851 273.567 215.389L273.563 215.401C263.661 245.981 238.138 268.913 206.677 275.499L206.665 275.501C175.232 282.216 142.61 271.578 121.176 247.625L121.168 247.616C99.6365 223.751 92.5378 190.181 102.565 159.642L102.569 159.631C112.471 129.051 137.994 106.119 169.455 99.5333Z" stroke="#EB5BE5" stroke-width="6"/>
<path opacity="0.04" d="M161.692 63.1073L161.698 63.106C206.143 53.6123 252.268 68.6526 282.573 102.521L282.577 102.525C313.021 136.269 323.059 183.734 308.881 226.913L308.879 226.919C294.878 270.156 258.791 302.581 214.307 311.893L214.301 311.894C169.857 321.388 123.732 306.347 93.4263 272.479L93.4222 272.475C62.9781 238.731 52.9409 191.266 67.1187 148.087L67.1206 148.081C81.1213 104.844 117.209 72.4185 161.692 63.1073Z" stroke="#EB5BE5" stroke-width="3"/>
<path opacity="0.2" d="M309.647 227.409V227.409C295.481 271.158 258.966 303.967 213.956 313.389V313.389" stroke="#EB5BE5" stroke-width="3"/>
<path opacity="0.2" d="M121.273 251.516L145.152 277.979C150.983 284.441 160.032 287.977 168.499 285.96V285.96" stroke="#EB5BE5" stroke-width="6.58471"/>
<path opacity="0.2" d="M262.439 282.549C249.84 282.549 239.627 272.514 239.627 260.137" stroke="#EB5BE5" stroke-width="6"/>
<path opacity="0.2" d="M87.5465 230.924C74.9477 230.924 64.7344 220.889 64.7344 208.512" stroke="#EB5BE5" stroke-width="6"/>
<path d="M319.47 119.707L316.882 123.107L314.268 119.707C315.826 118.629 317.912 118.629 319.47 119.707Z" fill="#3EB6AE"/>
<defs>
<linearGradient id="paint0_linear_1975_3245" x1="206.444" y1="137.071" x2="170.927" y2="245.992" gradientUnits="userSpaceOnUse">
<stop stop-color="#101010"/>
<stop offset="0.662523" stop-color="#101010" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint1_linear_1975_3245" x1="206.444" y1="137.071" x2="170.927" y2="245.992" gradientUnits="userSpaceOnUse">
<stop stop-color="white" stop-opacity="0.19"/>
<stop offset="0.619295" stop-color="#D8D8D8" stop-opacity="0"/>
</linearGradient>
</defs>
</svg>

            </div>
            <div style={{
                position: "inherit",
                zIndex: 1,
            }}>
            <Slider 
            inners={[[
                {
                    title: t("esim.section5.element1.item1.title"),
                    content: t("esim.section5.element1.item1.sub_title"),
                    image: "/media/slider/1.png",
                    image_mobile: "/media/slider/1_.png"
                },
                {
                    title: t("esim.section5.element1.item2.title"),
                    content: t("esim.section5.element1.item1.sub_title"),
                    image: "/media/slider/2.png",
                    image_mobile: "/media/slider/2_.png"
                },
                {
                    title: t("esim.section5.element1.item3.title"),
                    content: t("esim.section5.element1.item1.sub_title"),
                    image: "/media/slider/3.png",
                    image_mobile: "/media/slider/3_.png"
                },
                {
                    title: t("esim.section5.element1.item3.title"),
                    content: t("esim.section5.element1.item1.sub_title"),
                    image: "/media/slider/4.png",
                    image_mobile: "/media/slider/4_.png"

                },
                {
                    title: t("esim.section5.element1.item3.title"),
                    content: t("esim.section5.element1.item1.sub_title"),
                    image: "/media/slider/5.png",
                    image_mobile: "/media/slider/5_.png"
                },
            ],[
                {
                    title: t("esim.section5.element2.item1.title"),
                    content: t("esim.section5.element1.item1.sub_title"),
                    image: "/media/slider/1.png",
                    image_mobile: "/media/slider/1_.png"
                },
                {
                    title: t("esim.section5.element2.item2.title"),
                    content: t("esim.section5.element1.item1.sub_title"),
                    image: "/media/slider/2.png",
                    image_mobile: "/media/slider/2_.png"
                },
                {
                    title: t("esim.section5.element2.item3.title"),
                    content: t("esim.section5.element1.item1.sub_title"),
                    image: "/media/slider/3.png",
                    image_mobile: "/media/slider/3_.png"

                },
                {
                    title: t("esim.section5.element2.item3.title"),
                    content: t("esim.section5.element1.item1.sub_title"),
                    image: "/media/slider/4.png",
                    image_mobile: "/media/slider/4_.png"
                },
                {
                    title: t("esim.section5.element2.item3.title"),
                    content: t("esim.section5.element1.item1.sub_title"),
                    image: "/media/slider/5.png",
                    image_mobile: "/media/slider/5_.png"
                }

            ]]}
            seperator={<div className="slider-seperator">
                <div className="slider-seperator__title">
                    <h1>Choose your plan</h1>
                </div>
                <div className="slider-seperator__content">
                    <p>Choose your plan and get your eSIM</p>
                </div>
            </div>
            }
            />

            </div>
            <p className='text-center' style={{color:"white",margin:"auto",display:"block", fontSize:"0.8rem", maxWidth:"400px"}}>
            {t("esim.section3.sub_title")}
            </p>
            </div>
            <h4 className='mt-5' style={{fontWeight:"700"}}>{t("esim.section6.faq")}</h4>
            <Questions />
            </div>
    )

        }


export {PlanDetails};

export default Store;