//stepper component for the stepper page
import React, { useContext, useEffect, useState } from "react";
import Button from "../../components/design/buttons/Button";
import ProgressBar from "./components/ProgressBar";
import Step from "./steps/Step";
import {getStepperData} from "../../services/stepper.service";
import RecommandedPlans from "../plans/recomended/recomendedPlans";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

import { useParams } from 'react-router';
import { useTranslation } from "react-i18next";
import { init } from "i18next";
// import { LangContext } from "../../App";


const Stepper = () => {
    const {t, i18n} = useTranslation();
    const [currentStep, setCurrentStep] = useState(1);
    const [loading, setLoading] = useState(true);
    const [steps, setSteps] = useState<any>([]);
    const [ended, setEnded] = useState(false);
    const [output, setOutput] = useState<any>([]);
    const navigate = useNavigate();
    const step = useParams().step;

    const handleNext = (value: any) => {
        console.log(value);       
        // if(value && (!value.value)){
        //     toast.error("Please select an option", {
        //         position: "bottom-center",
        //         autoClose: 5000,
        //     }
        //     );
        //     return;
        // }

        if (currentStep < steps.length+1) {
            setCurrentStep(currentStep + 1);
        }
        if (currentStep === steps.length-1) {
            setTimeout(() => {
            localStorage.setItem("output", JSON.stringify(output));
            toast.loading("We are looking for the best plans for you", {
                position: "bottom-center",
            }
            );
            navigate("/recommended");
            toast.dismiss();
            }, 5000);
        } else {
            toast.clearWaitingQueue();
            toast.dismiss();
        }
        //check if the value.questionKey is in the output array
        if(value){
            if(output)
            {
                let newOutput = output;
                const index = newOutput.findIndex((item: any) => item.questionRefrence === value.questionRefrence);
                if (index !== -1) {
                    newOutput[index] = value;
                } else {
                    newOutput.push(value);
                }
                setOutput(newOutput);
            } else {
                console.log("output is empty");
                setOutput([value]);
            }
        }



        // setOutput([...output, value]);
        
    };
    
    const handleBack = () => {
        if (currentStep > 1) {
        setCurrentStep(currentStep - 1);
        }
        setEnded(false);
        //set

        setOutput(output.slice(0, -1));

    };
    
    const handleReset = () => {
        setCurrentStep(1);
        setEnded(false);
        setOutput([]);
    };


    const fetchSteps = async () => {
        setLoading(true);
        const data = await getStepperData(i18n.language);
        //push in front of the array
        data.unshift({
            title: "Choose your plan",
            type: "radio",
            items: [
              { label: "Option 1", value: "option1" },
              { label: "Option 2", value: "option2" }
            ], hint: "Choose one"
          });
          console.log(data);

        setSteps(data);
        setLoading(false);
    };


    useEffect(() => {
        fetchSteps();
        setCurrentStep(step ? parseInt(step) : 1);
        const output = localStorage.getItem("output");
        if (output) {
            setOutput(JSON.parse(output));
        }
        
    }, []);

    return (
        <div className="bg-element--1 overflow-scroll">
        <div className="bg-element--2 d-block d-lg-flex">   
        <div className="container mt-3">
        <div className="py-4 py-lg-5">
            <div className="row justify-content-between">
            <div className="col" >
            <Button color="circle" 
             isfullWidth={false}  inner={
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.50977 18.4898L18.4898 1.50977" stroke="#2F1952" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M18.4898 18.4898L1.50977 1.50977" stroke="#2F1952" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            } onClick={()=>{
                navigate(-1);
            }}/>
            </div>
                <div className="col justify-content-end d-flex">
                {
                    [1, 2, 3].map((step) => {
                        return (
                            <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg" style={{transform: i18n.language == "ar" ? "scaleX(-1)" : "scaleX(1)"}} >
                            <path d="M0 0C4.13333 0.133333 8.0001 0.933327 11.6001 2.39999C15.6001 4.06666 19.0335 6.29998 21.9001 9.09998C24.7668 11.9666 27.0333 15.3 28.7 19.1C30.3666 22.9 31.2 26.9667 31.2 31.3C31.2 32.7 31.1001 34.0667 30.9001 35.4H14.9001C15.3001 34.1333 15.5 32.7667 15.5 31.3C15.5 29.1667 15.1 27.1667 14.3 25.3C13.5 23.3667 12.3668 21.7 10.9001 20.3C9.43348 18.9 7.66676 17.7667 5.6001 16.9C3.86676 16.2333 2 15.8667 0 15.8V0Z" fill="#3EB6AE"/>
                            </svg>
                        );
                    })
                }
                </div>
            </div>
        </div>
        {!loading  && currentStep !=1 ?
        <div className="row">
            <div className="col-12">
                
            <ProgressBar currentStep={currentStep} steps={steps.length+1} />
            {
                currentStep !=2 && <Button color="circle mt-3"   isfullWidth={false} onClick={handleBack} inner={
                    <svg style={{transform: i18n.language == "ar" ? "scaleX(-1)" : "scaleX(1)"}} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.95 31.3669C16.2666 31.3669 16.5833 31.2502 16.8333 31.0002C17.3166 30.5169 17.3166 29.7169 16.8333 29.2335L7.59997 20.0002L16.8333 10.7669C17.3166 10.2835 17.3166 9.48353 16.8333 9.0002C16.35 8.51686 15.55 8.51686 15.0666 9.0002L4.94996 19.1169C4.46663 19.6002 4.46663 20.4002 4.94996 20.8835L15.0666 31.0002C15.3166 31.2502 15.6333 31.3669 15.95 31.3669Z" fill="#292D32"/>
                    <path d="M6.1165 21.25H34.1665C34.8498 21.25 35.4165 20.6833 35.4165 20C35.4165 19.3167 34.8498 18.75 34.1665 18.75H6.1165C5.43317 18.75 4.8665 19.3167 4.8665 20C4.8665 20.6833 5.43317 21.25 6.1165 21.25Z" fill="#292D32"/>
                    </svg>
                    
                 } />
            }
            </div>
        </div>
        : null}
        </div>
        
            
                
                    
                {
                    loading ? 
                    
                    <div className="row mt-3">
    <div className="col-lg-12">
        <div className="d-flex justify-content-center">
            <div className="spinner-border " role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
</div>
    : !ended ? 
                    <div className="container mb-3">
                        <Step currentStep={currentStep} steps={steps.length} nextStep={handleNext} prevStep={handleBack} step={steps[currentStep-1]} 
                         lang={i18n.language}  />
                    </div>
                    : <RecommandedPlans/>

                }
            
            
            {/* <Step currentStep={currentStep} steps={5} nextStep={handleNext} prevStep={handleBack} /> */}
        </div>
        </div>
    );
};


export default Stepper;