import Header from "../../components/header/header";
import '../about/about.css'
import Footer from "../../components/footer/footer";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';

const Terms = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const isHeadless = searchParams.get('headless');

    return <div >
        <div className="container about mb-5" >
    {
                isHeadless !== 'true' &&
                <Header/>
        
    }
   <TermsInner/>
   
</div>
    <Footer/>
</div>

}

export const TermsInner = () => {
    const { i18n} = useTranslation();
    return  (
        i18n.language === "ar" ?
    <div style={{
        maxWidth: "900px",
        margin: "auto",
        marginTop: "50px",
    }} className="terms"
    >
        <div className="wpb_wrapper">
<p>&nbsp;</p>
<h1 style={{textAlign: "center"}}><b>مرحبًا بكم في تطبيق “تايقو”</b></h1>
<p>&nbsp;</p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>&nbsp;</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” تابع لـ </span><b>شركة مستقبل التواصل</b><span style={{fontWeight: "400"}}>، والذي يتم من خلاله عرض ومقارنة خدمات الاتصالات أون لاين (شريحة تقليدية، شريحة إلكترونية، باقات انترنت، باقات الهاتف المتنقل)، فيستطيع المستخدم من خلال التطبيق الاطلاع على خدماتنا مع التمتع بطرق دفع إلكترونية آمنة.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>هذه الاتفاقية القانونية (المشار إليها فيما بعد بعبارة “الاتفاقية”) تحدد وتحكم استخدامك لتطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” ومن المهم قراءتها وفهمها، وبالنقر على كلمة “أوافق” “AGREE” فإنك توافق على أن هذه الأحكام والشروط سوف تـُـطبق في حالة ما إذا اخترت الوصول إلى خدمة “</span><b>تايقو”</b><span style={{fontWeight: "400"}}> أو استخدامها. وإذا كنت دون سن الرشد فعليك مراجعة هذه الاتفاقية مع أحد والديك أو ولي أمرك وذلك للتأكد من أنك ووالدك أو والدتك أو ولي أمرك تفهمانها.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>حيث أننا نقدم خدماتنا وفق الشروط والأحكام الآتية:</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>&nbsp;</span></p>
<p style={{textAlign: "right"}}><strong>أولاً: التعريفات</strong></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>&nbsp;يقصد بالعبارات والمصطلحات التالية – أينما وردت في هذه الاتفاقية – المعاني الموضحة أمام كل منها، ما لم يقتضي السياق غير ذلك:</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>“</span><b>تايقو</b><span style={{fontWeight: "400"}}>” أو “</span><b>التطبيق</b><span style={{fontWeight: "400"}}>” أو “</span><b>الموقع</b><span style={{fontWeight: "400"}}>” أو “</span><b>نحن</b><span style={{fontWeight: "400"}}>” أو “</span><b>ضمير المتكلم</b><span style={{fontWeight: "400"}}>” أو “</span><b>ضمير الملكية</b><span style={{fontWeight: "400"}}>“: يشير إلى “</span><b>تايقو</b><span style={{fontWeight: "400"}}>”&nbsp; </span><b>و شركة مستقبل التواصل</b><span style={{fontWeight: "400"}}> بالمملكة العربية السعودية.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>“</span><b>المستخدم</b><span style={{fontWeight: "400"}}>” أو “</span><b>أنت</b><span style={{fontWeight: "400"}}>” أو “</span><b>ضمير الملكية للمخاطب</b><span style={{fontWeight: "400"}}>“: يعني الشخص الذي يزور التطبيق، أو يستخدمه، أو يُسجل عضوية بالتطبيق.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>“</span><b>الخدمات</b><span style={{fontWeight: "400"}}>” تشير إلى الخدمات المعروضة عبر التطبيق والتي تشمل (شرائح تقليدية، شرائح إلكترونية، باقات انترنت، باقات الهاتف المتنقل، وشحن الرصيد).</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>“</span><b>المحتوى</b><span style={{fontWeight: "400"}}>” يشير إلى محتوى التطبيق والذي يشمل على سبيل المثال لا الحصر (الصور والنصوص والفيديو والمعلومات والبيانات والأسعار وقوائم الخدمات ووصف الخدمات والإعلانات وكافة الأيقونات والرموز والحروف والأرقام).</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>“</span><b>الاتفاقية</b><span style={{fontWeight: "400"}}>” تشير إلى الشروط والأحكام وسياسة الخصوصية وكافة صفحات التطبيق الرئيسية والفرعية ووصف الخدمات وكافة المعلومات ومحتوى الخدمات وكافة التعليمات والاتفاقيات الخاصة التي تتم بين التطبيق من ناحية وأي من مستخدمي التطبيق من ناحية أخرى، وكذلك العقود المرتبطة بالشروط والأحكام.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>“</span><b>أطراف الاتفاقية</b><span style={{fontWeight: "400"}}>” يشير إلى التطبيق من ناحية كـ (طرف أول في العلاقة التعاقدية)، وأي شخص يزور التطبيق أو يستخدمه أو يسجل حساب بالتطبيق أو يقدم أي من خدمات التطبيق أو يستفيد من خدماتنا بأي شكل من الأشكال من ناحية أخرى كـ (طرف ثان في العلاقة التعاقدية).</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>&nbsp;</span></p>
<p style={{textAlign: "right"}}><strong>ثانيًا: خدماتنا</strong></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” متخصص في استعراض ومقارنة خدمات الاتصالات والإنترنت أون لاين حيث يستطيع العميل تسجيل عضوية بالتطبيق للاستفادة من خدماتنا بشكل كامل.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>&nbsp;</span></p>
<p style={{textAlign: "right"}}><strong>ثالثًا: الحدود القانونية لخدماتنا</strong></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يعمل التطبيق كمنصة إلكترونية لعرض الخدمات، فهو بمثابة الأداة الإعلانية الخاصة بنا، التي ننشر من خلالها كافة المعلومات الخاصة بالخدمات المعروضة لمنح العميل استعراض ومقارنة العروض المقدمة من المشغلين لشركة الاتصالات في المملكة العربية السعودية.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>تعد الإعلانات عن الخدمات المنشورة عبر التطبيق بمثابة “دعوة للتعاقد” وليس “إيجابًا ملزمًا”.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>أنت تعلم وتوافق على أن دور التطبيق يقتصر على تسويق الخدمات الخاصة بمزودي الخدمات، بينما يتم تقديم الخدمة وتفعيلها من جانب موردي الخدمات، وبالتالي فالتطبيق لا يعد مقدم خدمة.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>لا يعد تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” مزود خدمة انترنت، ولا متعهد إيواء، ولا موفر محتوى معلوماتي، ولا يعامل كناشر لأي محتوى يتم نشره عبر التطبيق أو من خلال أي خاصية متاحة للتواصل عبر التطبيق.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>أنت تعلم وتقر بالموافقة على إعفاء تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” من أي مسئولية تقع عن عمل الغير.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>&nbsp;</span></p>
<p style={{textAlign: "right"}}><strong>رابعًا: الطبيعة القانونية للاتفاقية</strong></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>تعد هذه الاتفاقية الاتفاق الكامل والنهائي بين تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” من ناحية، وأي شخص يقوم بزيارة التطبيق أو الموقع أو يستخدمه أو يستخدم أي من خصائصه أو ميزاته من ناحية أخرى.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>تعد هذه الاتفاقية عقدًا صحيحًا مكتملاً الشروط والأركان القانونية، نافذًا في مواجهة جميع الأطراف المذكورة، وأحكامه والتزاماته ملزمة لهم جميعًا، ولا يجوز لأحد التحلل منها أو إنهائها طالما أنتجت آثارها القانونية.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يقر جميع الأطراف أن هذه الاتفاقية تشكل كامل الاتفاق بينهم، وقد اتفقوا على أنهم لم يعتمدوا على أي تأكيدات، شفوية كانت أم كتابية، في الموافقة على هذه الاتفاقية بخلاف الأحكام المبينة فيها.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يعد وصف الخدمات والصفحات الفرعية للتطبيق التي تم إعدادها من قِبل تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” جزء لا يتجزأ من هذه الاتفاقية.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>تعتبر العقود المكملة لهذه الاتفاقية جزء لا يتجزأ منها، ويسري عليها ما يسري على هذه الاتفاقية من شروط وأحكام، وترتبط بهذه الاتفاقية ارتباطًا لا يقبل التجزئة وجودًا وعدمًا.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يكون للتعديلات الواردة على هذه الاتفاقية نفس الحكم والأثر القانوني لهذه الاتفاقية.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>&nbsp;</span></p>
<p style={{textAlign: "right"}}><strong>خامسًا: الموافقة والأهلية القانونية</strong></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>تقر بأن لديك الأهلية القانونية اللازمة للإبرام والموافقة على هذه الاتفاقية، وأن لديك الصلاحيات القانونية الكاملة غير المقيدة طبقًا للشروط التالية:</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>أهلية وموافقة الشخص الطبيعي. يشترط في مُستخدِم تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” أن يكون بلغ من العمر 18 عامًا فأكثر وأن يتوفر فيه الأهلية القانونية اللازمة لإبرام العقود، ونحن غير مسئولين عن التحقق من أهلية أيًا من مستخدمي التطبيق، وباستخدام خدمات تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>“، فأنت توافق على هذه الاتفاقية، وتقر بأنك ملزمًا قانونًا بالشروط والأحكام المنصوص عليها في هذه الوثيقة أو تعديلاتها.&nbsp;</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>أهلية وموافقة القاصر (من هم أقل من 18 عام). إذا كنت تحت سن 18 عامًا، يمكنك استخدام خدمات تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” فقط تحت إشراف أحد الوالدين أو الوصي، ومع عدم الإخلال بأي حقوق أخرى لتطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” بموجب هذه الاتفاقية أو القانون، يحتفظ التطبيق بالحق في تقييد وصولك إلى التطبيق أو إلغاء عضويتك إذا رأى أنك لم تبلغ سن 18 عامًا.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>الصفة القانونية وموافقة المنشآت التجارية. إذا كنت تسجل كمنشأة تجارية، فأنت تقر بأن لديك سلطة إلزام هذه المنشأة باتفاقية المستخدم هذه، وأنك والمنشأة التجارية التي تمثلها سوف تخضعون لجميع القوانين السارية المتعلقة بالتداول عبر شبكة الإنترنت.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>&nbsp;</span></p>
<p style={{textAlign: "right"}}><strong>سادسًا: تسجيل عضوية العملاء</strong></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يشترط أن يُسجل الشخص الطبيعي في التطبيق باسمه الحقيقي، وأن يبتعد عن استخدام أي أسماء مستعارة أو غير حقيقية أو مضللة، وبمجرد قيامك بالتسجيل كشخص طبيعي فأنت تمثل نفسك فقط، فالحساب قائم على الاعتبار الشخصي.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يشترط أن يُسجل الشخص المعنوي في التطبيق باسمه التجاري، وأن يبتعد عن استخدام أي أسماء مجهولة أو غير حقيقية أو مضللة. وبمجرد قيامك بالتسجيل كشخص معنوي فأنت تقر بأنك الممثل القانوني لهذا الشخص.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يجب على المستخدم تزويدنا ببيانات تسجيل العضوية والتي قد تشمل على سبيل المثال لا الحصر (الاسم، العنوان، البريد الإلكتروني، رقم الجوال، رقم الهوية الوطنية).</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>العضوية بالتطبيق مجانية ومباشرة للمستخدمين ولا تحتاج إلى مراجعة من جانب إدارة التطبيق.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يتعهد المستخدم بالمحافظة على المعلومات السرية الخاصة بحسابه، مثل اسم المستخدم وكلمة المرور، وأنه مسئول عن أي إفصاح للغير عن هذه المعلومات، كما أنه مسئول عن أي استخدام يقع من أي شخص قام بالإفصاح له عن هذه المعلومات السرية.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يتعهد المستخدم بإبلاغ التطبيق فورًا في حالة اختراق أو سرقة الحساب أو اكتشاف أي استخدام غير قانوني لحسابه بالتطبيق، وذلك حتى نستطيع اتخاذ الإجراءات التقنية اللازمة للمحافظة على الحساب.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>لا يجوز لك استخدام حساب شخص آخر في أي وقت دون الحصول على موافقة صريحة من تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>“.&nbsp;</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>نحتفظ بالحق في إلغاء الحسابات التي لم يتم تأكيدها أو الغير نشطة لفترة طويلة.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>&nbsp;</span></p>
<p style={{textAlign: "right"}}><strong>سابعًا: التوقيع الرقمي</strong></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>في الخدمات التي تتطلب التسجيل: فإنه من خلال تسجيلك للحصول على حساب بالتطبيق، أو عن طريق النقر لقبول شروط الخدمة عند المطالبة بذلك على التطبيق، يعتبر أنك قد نفذت هذه الاتفاقية وشروط الخدمة الأخرى إلكترونيًا، وتعد نافذة المفعول قانونًا في مواجهتك من تاريخ تسجيل حسابك أو من تاريخ النقر لقبول شروط الخدمة.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>في الخدمات التي لا تتطلب التسجيل: يعد استخدامك لهذه الخدمات موافقة صريحة منك على الشروط والأحكام المنصوص عليها في هذه الوثيقة كافة السياسات الأخرى، وتعد ملزمًا قانونًا بها من تاريخ هذا الاستخدام.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>&nbsp;</span></p>
<p style={{textAlign: "right"}}><strong>ثامنًا: سياسة الاستخدام المقبول&nbsp;</strong></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يقر المستخدم بأنه بموجب موافقته على إبرام هذه الاتفاقية فإنه لن يستند أو يعتمد على أي تعهدات أو ضمانات أو تأكيدات أخرى بواسطة أو بالنيابة عن تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” باستثناء ما هو منصوص عليه في هذه الاتفاقية.&nbsp;</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>لا يشمل هذا الترخيص إعادة بيع أو أي استخدام تجاري لأي من خدماتنا أو محتوياتها، كما لا يشمل أي نسخ للمعلومات المتوفرة عن الحساب لصالح الغير، أو أي استخدام لوسائل استخراج البيانات أو أي استخدام لأدوات جمع واستخلاص البيانات المماثلة.&nbsp;</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>لا يجوز لك إعادة إنتاج أو عمل نسخة مطابقة لهذا التطبيق، أو نسخ أو بيع أو إعادة بيع أي جزء منه، أو استخدامه بصورة مغايرة في أغراض الاستغلال التجاري أو غير التجاري له دون الحصول على موافقة كتابية صريحة من تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>“.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>لا يجوز لك استخدام أي علامات وصفية أو أي “نص مخفي” آخر يستغل اسم “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” أو علاماتها التجارية بدون موافقة كتابية صريحة من التطبيق.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يجب أن تكون جميع المعلومات التي تفصح عنها حقيقية ومحدثة وصحيحة وتعبر عنك وتوافق ما هو مطلوب في استمارة التسجيل لدينا.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يتعهد المستخدم بتحري الدقة عند إدخال المعلومات المطلوب إدخالها في التطبيق، ويكون مسئولاً عن مراجعة تلك المعلومات بشكل دوري بغرض تصحيحها أو تعديلها أو تجديدها كلما توفرت لديك معلومات جديدة بشأنها، على أن تحتفظ بكافة البيانات والمستندات الدالة على ذلك.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>إذا قدمت معلومات غير صحيحة أو غير دقيقة أو غير متزامنة أو غير كاملة، أو إذا كان لدينا أسباب معقولة للاشتباه في أن هذه المعلومات غير صحيحة أو غير دقيقة أو غير متزامنة أو غير كاملة أو لا تتوافق مع اتفاقية الاستخدام هذه، ودون الإخلال بأي حقوق أخرى بموجب هذه الاتفاقية أو القانون، سوف نقوم بتعليق عضويتك أو إلغائها.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>غير مرخص لك بأي شكل من الأشكال نشر أي روابط لمواقع أو تطبيقات أخرى عبر التطبيق أو من خلال أي خواص متاحة داخل التطبيق.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>أنت توافق على عدم استخدام تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” أو أي خدمة تقدم من خلاله بطريقة غير قانونية أو احتيالية أو معادية للمجتمع على النحو الذي نقدره.&nbsp;</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يتحمل المستخدمون كافة المسئوليات القانونية في حالة قيامهم بانتهاك أي حقوق ملكية شخصية أو ملكية فكرية على أي من المحتوى الذي نقوم بنشره عبر التطبيق.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>نحن غير مسئولين عن قيام المستخدم بانتهاك أي حقوق خاصة بالغير وهي مسئولية المستخدم وحده.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>التطبيق لا يتحمل أي مسئولية بخصوص سوء استخدام المحتوى، وهي مسئولية المستخدم الذي يُسئ استخدام المحتوى، ويعد المستخدم في هذه الحالة مسئولاً في مواجهتنا عن استخدامه للمحتوى الخاص بالتطبيق.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>أنت مسئولاً عن المحافظة على استخدام التطبيق بكل جدية ومصداقية. </span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يجوز لك استخدام خدماتنا بالشكل المسموح به قانونًا فقط، ووفقًا لشروط هذه الاتفاقية.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>لا يجوز لك إساءة استخدام خدماتنا بأي شكل من الأشكال.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يلتزم المستخدم بإخطارنا في حالة اكتشافه لأي استخدام غير مشروع للتطبيق.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>أنت تقر بأنك سوف تقوم بإخطارنا في حالة وجود أي منشورات أو مواد أو معاملات يبدو أنها تنتهك اتفاقية الاستخدام.&nbsp;</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>قد يقترن استخدام تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” بتوفير خدمات أو محتوى جهات أخرى، والتي لا تخضع لسيطرتنا، وبالتالي فأنت تقر بسريان شروط وأحكام وسياسات خصوصية أخرى على استخدامك لخدمات ومحتوى الجهات غير التابعة لنا.&nbsp;</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>خدمات التطبيق متوفرة داخل المملكة العربية السعودية، وبالتالي فنحن غير ملزمين بتوفير أي طلبات في أي دولة أخرى، ومع ذلك تستطيع الوصول إلى التطبيق من أي مكان في العالم.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>تتعهد بتوفير المعلومات والمستندات اللازمة التي يطلبها منك التطبيق في أي وقت؛ سواء أثناء الاشتراك معنا وتسجيل حسابك، أو أثناء تقديم الخدمات لك بعد تسجيل حسابك.&nbsp;</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>جميع الحقوق غير الممنوحة لك صراحة في شروط الاستخدام هذه أو أي شروط خدمة أخرى يحتفظ بها تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>“.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>تنتهي التراخيص الممنوحة من قِبلنا إذا لم تلتزم بشروط الاستخدام هذه أو أي شروط خدمة أخرى.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>&nbsp;</span></p>
<p style={{textAlign: "right"}}><strong>تاسعًا: سياسة توفير الخدمات</strong></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يحرص تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” على تقديم كافة التعهدات والضمانات القانونية للعميل، وذلك طبقًا للأحكام القانونية المعمول بها في المملكة العربية السعودية، وذلك على النحو الآتي:</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يعرض التطبيق خدمات الاتصالات والإنترنت الخاصة بالشركات المختلفة داخل المملكة العربية السعودية، ويستطيع العميل الدخول على التطبيق وفلترة الخدمات وفقًا لنوعها أو وفقًا للشركة التي تقدمها.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يعلم العميل ويوافق على أن كل مزود خدمة له سياسة شروط وأحكام مستقلة به يلتزم العميل بها قبل استخدام خدماته المتاحة من خلال التطبيق، وقد يفرض مزودالخدمة شروط خاصة بكل خدمة يقدمها، وقد تختلف شروط الخدمات المتماثلة من مزود خدمة إلى مزود خدمة آخر.&nbsp;</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يضمن مزودي الخدمات امتلاكهم لكافة الحقوق والصلاحيات القانونية لبيع الخدمات، كما يضمنوا حصولهم على كافة التصاريح اللازمة لبيع الخدمة.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يعرض “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” أسعار وتفاصيل الخدمات في التطبيق وفقاً لما توفره مزودي الخدمات، مع مراعاة التحقق من صحة هذه المعلومات.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يتعهد التطبيق ببذل أقصى الجهود الممكنة للحصول على ضمانات من شركات تصنيع الخدمات، إن وجدت، ويتنازل بموجب هذه الاتفاقية عن تلك الضمانات إلى العميل وفقًا للمدة المسموح به.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يحتفظ التطبيق بملكية المحتوى الخاص بالخدمات وكافة الصور والملفات المرئية، ويتمتع بحقوق نشرها في أي وقت.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>&nbsp;</span></p>
<p style={{textAlign: "right"}}><strong>عاشرًا: سياسة التقييمات</strong></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>&nbsp;يقدم التطبيق خدمة التقييم وإضافة التعليقات للعملاء ، وذلك بهدف تقديم خدمة أفضل لجميع عملائنا، ويشترط في إضافة التعليقات الآتي:</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يجب أن تكون التعليقات صادقة وقانونية ومعبرة عن الخدمة التي تم تقديمها للعميل.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يحظر استخدام كلمات أو عبارات أو ألفاظ غير قانونية أثناء عملية التقييم.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يحظر استخدام شعارات أو أسماء مواقع أو تطبيقات أخرى أو علامات تجارية مشهورة أو غير مشهورة أثناء قيامك بعملية التقييم.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يحظر عمل مقارنات بين خدماتنا وخدمات منافسينا بما يسيء لنا أو للمنافسين.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يحظر الإساءة إلى الشعوب أو الدول أو الثقافات أو المجتمع أو أي قيم إنسانية أو اجتماعية أو دينية أثناء عملية التقييم.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يحظر نشر أي إعلانات أو ترويج لسلع أو خدمات من خلال خدمة التقييم الخاصة بالتطبيق الخاص بنا.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يحتفظ التطبيق بالحق في عدم نشر التعليقات إذا كانت غير قانونية أو غير صادقة من وجهة نظرنا.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يحتفظ التطبيق بالحق في حذف أي تعليقات تم نشرها إذا كانت تخالف الأحكام السابقة أو أي من الشروط والأحكام أو اتفاقية البيع أو سياسة الخصوصية.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يحتفظ التطبيق بحقه القانوني في اللجوء إلى القضاء في حالة الإساءة إلينا من خلال خدمة التعليقات.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يحق للغير ممن تم الإساءة إليه من خلال خدمة التعليقات اللجوء إلى القضاء ضد العميل الذي قام بإضافة التعليقات غير القانونية وذلك دون الرجوع علينا.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>&nbsp;</span></p>
<p style={{textAlign: "right"}}><strong>حادي عشر: حقوق التأليف والنشر</strong></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>التطبيق والأفكار المُعبَر عنها داخله، هي حقوق ملكية فكرية خالصة لنا، وأي تقليد أو اقتباس للتطبيق أو بعض خدماته (بما في ذلك الأفكار والنصوص والرموز والبرمجيات) يعد انتهاكًا لحقوق التأليف والنشر الخاصة بنا، وسنقوم معه باتخاذ كافة الإجراءات القانونية ضد مرتكب الانتهاكات المذكورة.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>جميع المحتويات المضمنة أو المتاحة ضمن خدمات تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” مثل (النصوص والشعارات والصور والرسوم البيانية والتسجيلات الصوتية وأيقونات الأزرار والمحتويات الرقمية والمواد التي يتم إعادة تحميلها والبرمجيات وتجميع البيانات) هي علامة مسجلة ومملوكة لـ </span><b>شركة مستقبل التواصل</b><span style={{fontWeight: "400"}}> وهي محمية من قِبل قوانين المملكة العربية السعودية وقوانين حقوق التأليف والنشر الدولية.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>تجميع كافة البيانات المدرجة في خدمة “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” أو إتاحتها من قِبل أيًا من خدماتنا هو ملك حصري وخاص بتطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” ومحمية بموجب قوانين حقوق الطبع والنشر السعودية والدولية وكذلك محمية بموجب الاتفاقيات الدولية السارية، مثل اتفاقية برن، واتفاقية تريبس.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>&nbsp;</span></p>
<p style={{textAlign: "right"}}><strong>ثاني عشر: العلامات التجارية&nbsp;</strong></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>“</span><b>تايقو”</b><span style={{fontWeight: "400"}}> والشعارات المرتبطة به هي علامات تجارية و/أو علامات خدمة خاصة بنا.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>الصور والشعارات ورؤوس الصفحات وأيقونات الأزرار والنصوص والأسماء الخدمية هي علامات تجارية وتصميمات تجارية خاصة بتطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>“.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>العلامات التجارية والخدمات المقدمة المملوكة لشركات الاتصالات هي مملوكة لمزودي الخدمة الأساسيين ويعتبر&nbsp; “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” وسيط لإعادة تقديم وعرض هذه الخدمات والعلامات التجارية.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يحظر إعادة إنتاج العلامات أو التصميمات التجارية الخاصة بتطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” في أي وسيلة إعلامية أو إعلانية دون إذن كتابي منا.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>لا يجوز استخدام العلامات والتصميمات التجارية الخاصة بتطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” في اتصال بأي منتج أو خدمة ليست تابعة لنا، حتى لا تتسبب في النيل من قدر ومصداقية تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” أو النيل من عملائها.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>&nbsp;</span></p>
<p style={{textAlign: "right"}}><strong>ثالث عشر: الاتصالات</strong></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>أنت توافق على تلقي الاتصالات منا، وسوف نتواصل معك عن طريق البريد الإلكتروني أو رقم الجوال أو تطبيقات التواصل الحديثة (على سبيل المثال واتساب)، أو عن طريق نشر إشعارات عبر التطبيق أو من خلال خدمات تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” الأخرى.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>أنت توافق على أن جميع الاتفاقات والإشعارات والإفصاحات وغيرها من الاتصالات التي نقدمها لك إلكترونيًا تستوفي كافة الشروط القانونية كما لو كانت هذه الاتصالات خطية، وتقوم مقامها في إنتاج آثارها القانونية.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يتطلب تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” موافقتك أثناء عملية التسجيل على قيامنا بإرسال رسائل على بريدك الإلكتروني أو هاتفك الجوال أو عبر التطبيق لأغراض ترويجية، وذلك لإعلامك بأي تغييرات أو ميزات أو أنشطة جديدة تضاف إلى التطبيق.&nbsp;</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>إذا قررت في أي وقت أنك لا ترغب في تلقي رسائل ترويجية، يمكنك تعطيل تلقي هذه الرسائل عن طريق مراسلتنا، ولكن في هذه الحالة لا نضمن تمتعك بخدماتنا بشكل كامل.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>&nbsp;</span></p>
<p style={{textAlign: "right"}}><strong>رابع عشر: الإشعارات</strong></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>أي إشعارات تود إرسالها لتطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” يجب أن يتم إرسالها عبر الوسائل المحددة داخل التطبيق، ولا يعتد بأي إشعارات يتم إرسالها خارج التطبيق مع إمكانية تلقي التواصل على المنصات الرسمية المملوكة لـ “</span><b>تايقو</b><span style={{fontWeight: "400"}}>“.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>أي إشعارات يود تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” إرسالها إليك، تكون إما عن طريق إعلانها على التطبيق أو عن طريق إرسالها إليك عبر رقم الجوال أو البريد الإلكتروني الذي زودتنا بهم خلال عملية التسجيل، ويفترض علمك بالإشعار بمجرد الإعلان على التطبيق، أو بمجرد مرور 24 ساعة من وقت إرسال الرسالة إليك.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>&nbsp;</span></p>
<p style={{textAlign: "right"}}><strong>خامس عشر: تحويل الحقوق والالتزامات&nbsp;</strong></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يحق لتطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” تحويل كافة حقوقه الواردة في هذه الاتفاقية للغير دون أي اعتراض من المستخدم، ويلتزم الجميع بتنفيذ كافة التزاماتهم الواردة في هذه الاتفاقية قِبل المحال إليه فور إخطارهم بالحوالة، ويحق للمحال إليه اتخاذ كافة الإجراءات القانونية قِبل المتقاعس عن تنفيذ التزاماته بموجب هذه الاتفاقية.&nbsp;</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>لا يجوز لك التنازل عن التزاماتك وحقوقك بموجب هذه الاتفاقية، أو أن تعهد بإدارة حسابك بالتطبيق إلى طرف ثالث إلا بعد الحصول على موافقة خطية منا.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>&nbsp;</span></p>
<p style={{textAlign: "right"}}><strong>سادس عشر: المسئولية القانونية</strong></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يجب عليك الالتزام بجميع القوانين واللوائح المعمول بها داخل الدولة التي تستخدم التطبيق من خلالها وذلك فيما يتعلق باستخدامك للتطبيق، وتتحمل كافة المسؤوليات الناشئة في حالة إخلالك بهذه القوانين أو اللوائح، كما تلتزم بجميع الشروط والأحكام المنصوص عليها في هذه الاتفاقية.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>في حالة مخالفة المستخدم لأي من شروط أو أحكام هذه الاتفاقية فيكون من حق تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” اتخاذ إجراء إداري داخل التطبيق يتمثل في وقف العضوية لفترة من الزمن أو عمل حظر دائم للمستخدم المخالف، ولا يحق له في هذه الحالة التسجيل في التطبيق مرة أخرى إلا بموافقة صريحة من تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>“.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يحق لكل طرف اتخاذ كافة الإجراءات القانونية التي يراها مناسبة وفقًا لتقديره دون الرجوع علينا بأي شكل من الأشكال ودون أدنى مسئولية على التطبيق.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يُسأل العميل بموجب هذه الاتفاقية وبموجب القانون. ويلتزم بتعويضنا في حالة قيامه بتقديم طلبات شراء وهمية أو غير جدية أو غرضها إزعاجنا أو تضييع وقتنا، كما يكون مسئول قانونًا أمام شركات الشحن في هذه الحالة.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>إذا قمت بانتهاك هذه الاتفاقية، فإن تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” يحتفظ بحقه في استعادة أية مبالغ مستحقة عليك، وأي خسائر أو أضرار تسببت فيها وللتطبيق الحق في اتخاذ الإجراءات القانونية أو اللجوء للمحاكم المختصة لرفع دعاوى مدنية أو جنائية ضدك.&nbsp;</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” لا يضمن اتخاذه إجراءات ضد كل الانتهاكات التي قد تحدث لاتفاقية الاستخدام هذه، ولا يعني عدم اتخاذنا للإجراءات القانونية في أي من حالات الانتهاك تنازلاً عن حقنا في اتخاذ هذه الإجراءات في أي وقت نراه مناسبًا.</span></p>
<p style={{textAlign: "right"}}><b>&nbsp;</b></p>
<p style={{textAlign: "right"}}><strong>سابع عشر: حدود مسؤوليتنا</strong></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>أنت توافق صراحة على أنك تستخدم تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” على مسئوليتك الشخصية.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>نقدم خدماتنا “كما هي متوفرة”، دون أي تعهدات أو ضمانات من أي نوع، سواء صريحة أو ضمنية، تتعلق باستخدام هذا التطبيق أو محتواه أو الخدمات المقدمة عليه.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>لن نتحمّل مسؤولية الخسائر أو الأضرار سواء المباشرة أو غير المباشرة أو التبعية أو العرضية التي تنشأ عن:</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>المعلومات الخاطئة في الخدمات أو التوصيات التي تتعلق بالخدمات.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>أيّ تعاملات تتم بين مقدمي الخدمات والعملاء خارج نطاق التطبيق.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>جودة الخدمات التي يقدمها مقدمي الخدمات.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>خسارة الأرباح أو المبيعات أو الأعمال أو الإيرادات أو توقف الأعمال أو ما شابه.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>فقدان فرص العمل، أو الشهرة، أو السمعة أو أي خسارة أو ضرر غير مباشر أو لاحق.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>أيّ إفصاح لبيانات العملاء من جانب مقدمي الخدمات.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>تقصير المستخدم في الحفاظ على أمان وسرية وخصوصية بيانات الحساب.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>التطبيق لا يضمن أن العيوب التقنية سيتم إصلاحها، أو أن التطبيق أو خوادمه خالية من الفيروسات أو أي شيء آخر يكون ضارًا أو مدمرًا، وأنت تعلم أن المواقع على شبكة الإنترنت دائمًا ما تكون عرضة لفساد البيانات أو عدم توفرها أو تأخر ظهورها، وأنت تقبل ذلك.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>التطبيق قد يكون غير متوفر من وقت لآخر بسبب أعمال الإصلاح والصيانة أو التطوير، وأنت توافق على أن التطبيق غير مُلزم بتقديم الدعم الفني في أي وقت من الأوقات.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>تقر بأن تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” عبارة عن خدمة قائمة على الإنترنت، وأنه على الرغم من بذلنا أقصى جهد للحفاظ على المعلومات وإبقائها آمنة، إلا أننا لا نستطيع أن نضمن أن تكون المعلومات التي يتلقاها المستخدم أو يقوم ببثها أثناء استخدامه للتطبيق آمنة في كل الأوقات.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>لا يقدم تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” أي ضمان ولا يتحمل أي مسئولية فيما يتعلق بحداثة وشيوع ودقة وجودة المعلومات التي يتلقاها المستخدم أو يتوصل إليها عبر التطبيق.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>تقع مسئولية استخدام المعلومات التي يتلقاها أو يتوصل إليها المستخدم من خلال خدمات تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” أو الاعتماد عليها على عاتق هذا المستخدم بشكل كامل.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” لا يقدم أي ضمانات بخلو هذا التطبيق أو الخوادم الخاصة به أو الرسائل المُرسلة من الفيروسات أو غيرها من المكونات الضارة.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” لا يستطيع أن يؤكد أن كل مستخدم للتطبيق هو فعلاً الشخص الذي يدعيه.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>لن يكون التطبيق مسئولاً في جميع الأحوال عن أي خسارة مباشرة أو غير مباشرة أو تبعية أو خسارة في الأرباح والشهرة أو ضرر أيًا كان، نتيجة الإفصاح عن اسم المستخدم الخاص بك و/أو كلمة المرور.&nbsp;</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” غير مسئول قانونًيا عن الخسائر أو الأضرار أيًا كان نوعها التي قد تنشأ عن استخدام هذا التطبيق بما في ذلك على سبيل المثال لا الحصر الأضرار المباشرة وغير المباشرة.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>قد نقوم في أي وقت بتعديل أو وقف أو قطع خدمات تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” بدون إرسال إخطار إليك بذلك، كما قد نقوم بوقف استخدامك لخدماتنا إذا قمت بانتهاك هذه الشروط والبنود أو إذا أسأت استخدام هذه الخدمات من وجهة نظرنا.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>مع عدم الإخلال بحقوقه الأخرى فإن لتطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” الحق في وقف أو إلغاء عضويتك أو وصولك إلى التطبيق في أي وقت وبدون إنذار ولأي سبب، ودون تحديد، ويمكنه إلغاء اتفاقية المستخدم هذه.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>&nbsp;</span></p>
<p style={{textAlign: "right"}}><strong>ثامن عشر: التعويضات</strong></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>أنت توافق على تعويض تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” وإبراء مسئوليته والدفاع عنه ضد كافة الدعاوى والمطالبات التي قد يرفعها أو يطالب بها الغير نتيجة استخدامك للتطبيق، أو بسبب قيامك بانتهاك هذه الشروط والبنود أو الإخلال بحقوق المستخدمين الآخرين.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يستثني التطبيق من ضماناته و بنوده وشروطه أي خسائر مالية قد تلحق بالعميل، أو تشويه في السمعة، أو أي أضرار خاصة تنشأ عن سوء استخدامك للخدمات أو التطبيق، وإن التطبيق لا يتحمل أي مسئوليات أو مطالبات في مثل هذه الحالات.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>لن يكون تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” ومسئوليه وموظفيه مسؤولين قانونًا تجاهك أو تجاه أي طرف آخر عن أي خسارة مباشرة أو غير مباشرة أو عن أي تكلفة أخرى قد تنشأ من أو فيما يتصل بتنفيذ اتفاقية الاستخدام، أو فيما يتصل بتقديم خدماتنا.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>على المستخدم أن يحمي تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” وموظفيه وأن يدافع عنهم ويعوضهم عن أية خسائر ناتجة عن أية دعوى أو مطالبة تتعلق بالتطبيق أو ناتجة عن عمل أو إهمال من قِبل المستخدم أو ممثليه أو وكلائه.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>أنت ملزمًا بالتعويض عن أي خسائر أو أضرار&nbsp; قد تلحق بالتطبيق نتيجة أي </span><span style={{fontWeight: "400"}}>استخدام غير شرعي أو غير مفوض من قِبلنا.</span></p>
<p>&nbsp;</p>
<p style={{textAlign: "right"}}><strong>تاسع عشر: التعديلات</strong></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يلتزم المستخدم بما يجريه التطبيق من تغييرات وتعديلات في السياسات المطبقة، وعليه قبول أية تعديلات يراها تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” لازمة، ويجوز لنا في أي وقت إجراء أية تعديلات أو تحسينات نراها ضرورية على التطبيق لزيادة فاعليته، ويلتزم المستخدم بأية توجيهات أو تعليمات يقدمها التطبيق إليه في هذا الخصوص.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>لا يجوز لك تغيير أي بنود من هذا الاتفاق أو تعديلها أو استبدالها بدون موافقة مكتوبة من تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>“.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>يجوز لنا تعديل أو تحديث هذه الشروط والأحكام “اتفاقية المستخدم” الخاصة باستخدام التطبيق بدون إرسال إخطار إليك بذلك؛ لذا عليك مراجعة هذه الاتفاقية بشكل دوري.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>أنت توافق على الالتزام بكافة الشروط والبنود الإضافية التي ستتاح لك والتي تتعلق باستخدام أي من الخدمات المتاحة عبر التطبيق، ويتم دمج هذه الشروط والبنود الإضافية إلى هذه الاتفاقية.</span></p>
<p>&nbsp;</p>
<p style={{textAlign: "right"}}><strong>عشرون: إلغاء الاتفاق</strong></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>&nbsp;يعتبر الاتفاق مفسوخًا من تلقاء نفسه دون حاجة إلى أعذار أو اتخاذ إجراءات قضائية في أي من الحالات الآتية:</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>في حالة ثبوت قيامك بالإخلال بأي فقرة أو بند من بنود هذه الاتفاقية أعلاه، مع حفظ كافة حقوقنا في المطالبة بالتعويض عن الأضرار المترتبة على ذلك.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>التنازل عن الحساب أو تأجيره من الباطن دون موافقتنا.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>تقاعس المستخدم عن الوفاء بأية التزامات منصوص عليها في هذه الاتفاقية دون أن يتخذ إجراءات جدية لإزالة هذا الإخلال، مع الاحتفاظ بحقنا في المطالبة بالتعويضات إن كان لها محل.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>عدم التمكن من توثيق أي معلومات قمت بتزويدنا بها.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>إذا قررنا في أي وقت أن نشاطاتك قد تتسبب لك أو لمستخدمين آخرين في نزاعات قانونية.</span></p>
<p>&nbsp;</p>
<p style={{textAlign: "right"}}><strong>واحد وعشرون: روابط المواقع الخارجية</strong></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>قد يحتوي تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” على روابط لمواقع خارجية خاصة بأطراف أخرى، وليس لدينا أي سيطرة على تلك المواقع أو محتوياتها، ولا نتحمل أي مسؤولية عنها أو عن أي خسارة أو ضرر قد ينشأ عن استخدامك لها، وتوافق على عدم إشراكنا في أيّ نزاع قد يكون لديك مع أيّ موقع إلكتروني لأي طرف ثالث.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>قبل تمكين أي من وظائف المشاركة في الموقع للتواصل مع أو زيارة أي موقع من هذه المواقع، نوصيكم بمراجعة وفهم الشروط والأحكام، وسياسة الخصوصية، والإعدادات، ووظائف تبادل المعلومات لكل موقع من مواقع الأطراف الثالثة.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>لا تشكل الروابط الموجودة على تطبيق “</span><b>تايقو</b><span style={{fontWeight: "400"}}>” موافقة منا على استخدام مثل هذه المواقع، ويكون استخدامك لهذه المواقع على مسؤوليتك الخاصة.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>&nbsp;</span></p>
<p style={{textAlign: "right"}}><strong>اثنان وعشرون: النظام الواجب التطبيق</strong></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>تخضع هذه الشروط وتُفسر وفقًا للأنظمة المعمول بها المملكة العربية السعودية.</span></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>إذا اعتبر أي من هذه الشروط غير قانوني أو باطل أو غير قابل للتنفيذ بموجب القوانين المعمول بها، فسيعتبر هذا الشرط مفصولاً عن هذه الشروط، ولا يؤثر على صحة ونفاذ باقي الأحكام.</span></p>
<p>&nbsp;</p>
<p style={{textAlign: "right"}}><strong>ثلاثة وعشرون: التعارض</strong></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>في حال تعارضت هذه الشروط مع أيّ من إصدارات سابقة لها، فإنّ النسخة الحالية تكون هي السائدة والمعتمدة.</span></p>
<p>&nbsp;</p>
<p style={{textAlign: "right"}}><strong>أربع وعشرون: اللغة</strong></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>كتبت هذه الشروط باللغتين العربية والإنجليزية، وستعتمد اللغة العربية لغرض تفسير وتنفيذ هذه الشروط أمام جميع الهيئات الرسمية وغير الرسمية إذا تعارضت اللغة الإنجليزية معه.</span></p>
<p>&nbsp;</p>
<p style={{textAlign: "right"}}><strong>خمسة وعشرون: الموافقة</strong></p>
<p style={{textAlign: "right"}}><span style={{fontWeight: "400"}}>أنت تقر بقراءة هذه الشروط وتوافق على الالتزام بأحكامها، وفي حال كان لديك أي أسئلة تتعلق بهذه الشروط، فلا تتردد في التواصل معنا عبر الوسائل المتاحة على التطبيق.</span></p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p style={{textAlign: "center"}}><span style={{fontWeight: "400"}}>حقوق الطبع والنشر ©</span> <b>تايقو</b><b> 2022</b></p>
<p style={{textAlign: "center"}}><span style={{fontWeight: "400"}}>جميع الحقوق محفوظة لـ </span><b>شركة مستقبل التواصل&nbsp;</b></p>

		</div>
</div>:
<div style={{
        maxWidth: "900px",
        margin: "auto",
        marginTop: "50px",
        
    }} className="terms"
    >
        <div className="wpb_wrapper">
			<h2 style={{textAlign: "center"}}><span style={{fontWeight: "400"}}>Welcome to the</span><b> “<span style={{color: "#3eb6ae"}}>Tygo</span>” </b><span style={{fontWeight: "400"}}>Application</span></h2>
<p>&nbsp;</p>
<p style={{textAlign: "left"}}><span style={{fontWeight: "400"}}>The “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application is affiliated with </span><b>Future communication company</b><span style={{fontWeight: "400"}}>., through which online communication services are displayed and compared (traditional SIM, electronic SIM, internet packages, mobile phone packages), so the usercan view our services through the Application while enjoying secure electronic payment methods.</span></p>
<p style={{textAlign: "left"}}><span style={{fontWeight: "400"}}>This legal agreement (hereinafter referred to as the “Agreement”) set out and governs your use of the “Tygo” Application, and it is important to read and understand it, and by clicking on the word “AGREE” you agree that these terms and conditions will apply in the event that you choose to access or use “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” service. If you are under the age of majority, you should revise this Agreement with your parent or guardian to ensure that you and your parent or guardian understand it.</span></p>
<p><span style={{fontWeight: "400"}}>Whereas we provide our services according to the following terms and conditions:</span></p>
<p>&nbsp;</p>
<p><b>FIRST: DEFINITIONS</b></p>
<p><span style={{fontWeight: "400"}}>In this Agreement, unless otherwise required by the context, the following terms shall have the meanings referred to.</span></p>
<ul>
<li style={{fontWeight: "400"}} aria-level={1}><b>“Tygo”, “Application”,&nbsp;”website”, “We”, “Us”, or “Our”&nbsp;</b><span style={{fontWeight: "400"}}>refers to the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application and </span><b>Future communication company</b><span style={{fontWeight: "400"}}> in KSA.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><b>“User”, “You” or “Your”</b><span style={{fontWeight: "400"}}>&nbsp;refers to the person who visits or uses the Application or registers a membership in the Application.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><b>“Services”</b><span style={{fontWeight: "400"}}>&nbsp;refers to the services available via the Application, which includes (traditional SIMs, electronic SIMs,internet packages, mobile phone packages, and revharge balance).</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><b>“Content”&nbsp;</b><span style={{fontWeight: "400"}}>refers to the Application content, which includes, but is not limited to (images, texts, videos, information, data, prices, service listing, service description, ads, icons, symbols, letters, and numbers).</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><b>“Agreement”</b><span style={{fontWeight: "400"}}>&nbsp;refers to the terms and conditions, the privacy policy, all home page or sub-pages of the Application, the descriptions of service, all information and content of the services, all instructions, and special agreements that are made between the Application on the one hand and any users of the Application on the other hand, as well as the contracts associated to the terms and conditions.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><b>“Parties to the Agreement”</b><span style={{fontWeight: "400"}}>&nbsp;refers to Application on the one hand as (a first party in the contractual relationship), and any person who visits or uses the Application, registers an account with the Application, provides any of the Application’s services, or benefits from our services in any way on the other hand as (a second party in the contractual relationship).</span></li>
</ul>
<p><b>SECOND: OUR SERVICES</b></p>
<p><span style={{fontWeight: "400"}}>“</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application specializes in reviewing and comparing online communications and internet services online, where the customer can register membership in the Application to benefit from our services fully.</span></p>
<p>&nbsp;</p>
<p><b>THIRD: LEGAL SCOPE OF OUR SERVICES</b></p>
<ol>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The Application works as an online platform for displaying services. It is our advertising tool, through which we publish all information about the offered services to give the customer a review and comparison of the offers provided by operators to the telecom operator in the KSA.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The advertisements of the services published via the Application shall be deemed to be “a call for contracting” and not a “binding offer”.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>You know that the role of the Application shall be limited to marketing the services of the service provider, while the service is provided and activated by service providers, and therefore the Application is not a service provider.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application shall not be deemed to be an internet service provider, shelter provider, information content provider, or service provider. Also, it shall not be treated as a publisher of any content published via the Application or via any property available for communication via the Application.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>You know of and agree on the exemption of the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application from any liability arising from the acts of third parties.</span></li>
</ol>
<p>&nbsp;</p>
<p><b>FOURTH: THE LEGAL NATURE OF THE AGREEMENT</b></p>
<ol>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>This Agreement shall be deemed to be the entire and final agreement between the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application on one hand and any person visiting or using the Application, website, or any of its features or advantages on the other hand.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>This agreement shall be deemed to be a valid contract satisfying the legal conditions and elements and shall be enforceable against all said parties. Its provisions and obligations shall be binding on all of them and neither party may derogate from it or terminate it since it has resulted in its legal effects.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>All parties acknowledge that this agreement constitutes the entire agreement among them. They agreed that they didn’t rely on any emphasis whether oral or written for accepting this agreement other than the provisions stated herein.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The description of the services and the sub-pages of the Application prepared by the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application shall form an integral part of this agreement.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The contracts supplementing this agreement shall be considered an integral part of it and they shall be governed by the terms and conditions which apply to this agreement. Also, this agreement shall be indivisible.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The amendments of this agreement shall have the same legal provision and effect to this agreement.</span></li>
</ol>
<p>&nbsp;</p>
<p><b>FIFTH: CONSENT &amp; LEGAL CAPACITY</b></p>
<p><span style={{fontWeight: "400"}}>You acknowledge that you have the legal capacity necessary for concluding and accepting this agreement and that you have the full unrestricted legal authorities according to the following conditions:</span></p>
<ol>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The capacity and consent of the ordinary person: It is stipulated for the user of the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application that he is 18 years old and has the legal capacity necessary for concluding contracts and we shall not be responsible for verifying the capacity of any of the users of the Application, With your use of the services of the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application, you agree on this agreement and you acknowledge are that you are legally bound by the terms and conditions provided for in this document or its amendments.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The capacity and consent of the minor: If you are under 18, you may use the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application only with the involvement of a parent or guardian, and without prejudice to any other rights and remedies of the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application under this agreement or at law, the Application reserves the right to limit or withdraw access to the Application or the membership of any person if we believe that person is under the age of 18 years.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The legal capacity and approval of a business entity: If you are registering as a business entity, you represent that you have the authority to bind that entity to this user Agreement and that you and the business entity will comply with all applicable laws relating to online trading.</span></li>
</ol>
<p>&nbsp;</p>
<p><b>SIXTH: REGISTRATION OF MEMBERSHIP</b></p>
<ol>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>It shall be stipulated that the natural person registers in the Application in his true name and not in an alias, untrue or misleading name. When you register as a natural person, you shall represent yourself only because the account is based on personal consideration.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>It shall be stipulated that the legal person registers in its trade name in the Application not unknown, untrue, or misleading names and once you register as a legal person, you acknowledge that you are the legal representative of this person.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The user shall provide us with the data of registering the membership that might include among other things (Name, Address, E-mail, Mobile Phone No., ID No.).</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The membership in the Application shall be for free and direct to the users and it shall not need review by the administration of the Application.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The user undertakes to maintain confidential information about his account, such as the username and password, and he is responsible for any disclosure of this information to others. Also, he is responsible for any use done by any person to whom he has disclosed this confidential information.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The user undertakes to report the Application immediately in case of penetrating the account or its theft as well as discovering any illegal use of this account in the Application in order for us to be able to take the technical actions necessary for maintaining the account.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>You may not use another person’s account at any time without having express consent from the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>We reserve the right to cancel the account that haven’t been confirmed yet or are inactive for a long time.</span></li>
</ol>
<p>&nbsp;</p>
<p><b>SEVENTH: DIGITAL SIGNATURE</b></p>
<ol>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>As for services requiring registration:&nbsp;Through your registration to get an account via the Application or via clicking for accepting the conditions of the service upon claiming so via the Application, you shall be considered to have implemented this agreement and the other conditions of the service online and it shall be legally enforceable for you from the date of registering your account or from the date of clicking for accepting the conditions of the service.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>As for services not requiring registration:&nbsp;your use of these services shall be deemed to be an express consent by you to the terms and conditions provided for in this document and all the other policies and you shall be legally bound by them from the date of this use.</span></li>
</ol>
<p>&nbsp;</p>
<p><b>EIGHTH: ACCEPTABLE USE POLICY</b></p>
<ol>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The user acknowledges that under this agreement he will not rely on any promises, warranties, or emphasis by or on behalf of the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application except what is provided for in this agreement.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>This license does not involve the reselling or any commercial use of any of our services or their content. Also, it doesn’t involve any copy of the information available about the account in the favor of third parties or the use of means of getting data as well as any use of the tools of collecting and eliciting similar data.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>You may not reproduce or make a typical copy of this Application, copy, sell, resell any part of it or use it in a different way for purposes of commercial or non-commercial exploitation of it without having express written consent from the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>You may not use any descriptive marks or any other “hidden text” which exploits the name “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” or its trademark without express written consent from the Application.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>All the information you disclose must be real, updated, correct, and express yourself, and match with what is required in the registration form we have.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The user undertakes to precisely enter the information required to be entered in the Application and he shall be responsible for regularly reviewing this information for the purpose of correcting, modifying, or renewing it as long as you have new information regarding this and you are required to keep all the data and documents proving that.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>In case you have provided incorrect, imprecise, non-synchronized, or incomplete information or in case we have reasonable reasons to suspect that this information is incorrect, imprecise, non-synchronized, incomplete, or inconsistent with this user agreement and without prejudice to any other rights under this agreement or the law, we will suspend or cancel your membership.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>You are not licensed in any of the forms to publish any link to websites or other applications via the Application or through any features available within the Application.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>You agree not to use the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application or any service provided via it in an illegal, deceitful or antisocial way as we determine.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The users shall bear all the legal liability in case they violate any personal property rights or intellectual property rights over any content published by them via the Application.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>we shall not be liable for the violation by the user of any of the rights of third parties and it shall be the user’s liability only.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The Application at any time shall be entitled to delete the content published by the users via the Application in case we see that this content violates the intellectual property rights, trademarks, the rights of privacy, trade rights, or other intellectual property rights of third parties.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>You shall be liable for the lack of the serious and credible use of the Application.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>You may use our services in the form only legally permitted and according to the conditions of the agreement.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>You may not misuse our services in any of the forms.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The user shall be bound to notify us in case he discovers any illegal use of the Application.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>You declare that you will notify us in case of the existence of any publications, materials, or transactions that seem to violate this agreement.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The use of the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application might be coupled with making available services or content of other bodies that aren’t under our control. Consequently, you declare that other conditions, provisions, and policies of privacy apply to your use of the services and content of the bodies not belonging to us.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The services of the Application shall be available within the KSA. Consequently, we shall not be bound to make available any services in any other state. Nevertheless, they can reach the Application anywhere in the world.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>You promise to make available the information and documents necessary and which is required by the Application from you at any time whether during the participation with us and the registration of your account or during the provision of services to you after registering your account.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>All rights not expressly granted to you to these conditions of or any conditions of another service shall be reserved by the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The licenses granted by us shall terminate in case you do not comply with these conditions of use or any conditions of another service.</span></li>
</ol>
<p>&nbsp;</p>
<p><b>NINTH: SERVICE PROVISION POLICY</b></p>
<p><span style={{fontWeight: "400"}}>The “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application is keen to provide all legal commitments and warranties to the customer, in accordance with the legal provisions in force in the KSA, as follows:</span></p>
<ol>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The Application displays telecommunications and internet services for various companies within the KSA, and the customer can access the Application and filter the services according to their type or according to the company that provides them.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The customer knows and agrees that each service provider has a separate terms &amp; conditions policy that the customer shall be bound by before using its services available through the Application, and the service provider may impose special terms for each service it provides, and the terms of similar services may vary from one service provider to another service provider&nbsp;</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The service providers warrant that they have all legal rights and powers to sell the services and that they obtain all necessary permissions to sell the services.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>“</span><b>Tygo</b><span style={{fontWeight: "400"}}>” displays prices and details of the services in the application as provided by service providers, taking into account the validation of this informationز</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The Application undertakes to make the utmost efforts to obtain warranties from service manufacturers if any and hereby assigns such warranties to the customer for the permitted period.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The Application retains ownership of the services content and all images and visual files and has the right to publish them at any time.</span></li>
</ol>
<p>&nbsp;</p>
<p><b>TENTH: ASSESSMENT POLICY</b></p>
<p><span style={{fontWeight: "400"}}>The Application provides the service of assessment and adding comments to customers, in order to provide a better service to all our customers, and it is required to add the following comments:</span></p>
<ol>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The assessments must be true and legal and expressive of the service provided to the customer.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>It is prohibited to use illegal words, phrases, or expressions during the assessment process.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>It is prohibited to use logos, names of other websites or applications, or famous or non-famous brands during the assessment process.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>It is prohibited to make comparisons between our services and those of our competitors in a way that offends us or competitors.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>It is prohibited to offend peoples, states, cultures, society, or any human, community, or religious values </span><span style={{fontWeight: "400"}}>&ZeroWidthSpace;&ZeroWidthSpace;</span><span style={{fontWeight: "400"}}>during the assessment process.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>It is prohibited to publish any advertisements or promotion of goods or services through the assessment service of our Application.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The Application reserves the right not to post comments if they are illegal or dishonest in our point of view.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The Application reserves the right to delete any comments posted if they violate the previous provisions or any of the terms and conditions, sales agreement, or privacy policy.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The Application reserves its legal right to resort to the judiciary in case of offending us through the comments service.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>Others who have been offended through the comments service have the right to resort to the judiciary against the customer who added the illegal comments without recourse to us.</span></li>
</ol>
<p><span style={{fontWeight: "400"}}>&nbsp;</span></p>
<p><b>ELEVENTH: COPYRIGHTS</b></p>
<ol>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The Application and ideas expressed within it shall be our intellectual property rights. Any imitation or plagiarism of the Application or some of its services (including ideas, texts, symbols, and software) shall be deemed to be a violation of our copyrights for which we will take all the legal procedures against the perpetrator of the said violations.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>All the contents included or available within the services of the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application such as (texts, logos, pictures, graphs, voice, recordings, icon buttons, digital contents, materials that are uploaded, software, and collection of data) shall be a registered trademark and owned by </span><b>Future communication company</b><span style={{fontWeight: "400"}}> and shall be protected by the KSA laws and the international copyrights laws.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The collection of all data inserted in the service of “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” or making it available by any of our services shall be exclusively and privately owned by the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application, and protected under the KSA laws of copyrights and the international ones and also shall be protected under the international conventions in force such as Bern Agreement and TRIPS Agreement.</span></li>
</ol>
<p>&nbsp;</p>
<p><b>TWELFTH: TRADEMARKS</b></p>
<ol>
<li style={{listStyleType: "none"}}>
<ol>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>“</span><b>Tygo</b><span style={{fontWeight: "400"}}>” and logos connected with it shall be our trademarks and/or our services marks.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The pictures, logos, headers of pages, icon buttons, texts, and services names shall be trademarks and commercial designs of the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application.</span></li>
</ol>
</li>
</ol>
<ul>
<li aria-level={1}><span style={{fontWeight: "400"}}>The trademarks and services provided by the telecom companies are owned by the main service providers and “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” is considered an intermediary for the re-presentation of these services and trademarks.</span></li>
</ul>
<ol>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>Reproduction of trademarks or commercial designs of the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application in any media or advertising means shall be prohibited without written permission from us.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>Trademarks and commercial designs of the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application may not be used for contacting any product or service that doesn’t belong to us not to cause harm to the position and credibility of the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application or to cause harm to its customers.</span></li>
</ol>
<p>&nbsp;</p>
<p><b>THIRTEENTH: TELECOMMUNICATIONS</b></p>
<ol>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>You agree on getting telecommunications from us and we will communicate with you by e-mail, mobile number, modern communication applications (eg WhatsApp), or by posting notices through the application or with the ability to receive communication on the official platforms owned by “</span><b>Tygo</b><span style={{fontWeight: "400"}}>”.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>You agree that all agreements, notices, disclosures, and other telecommunications done by us online satisfy all the legal conditions as if they were written with all their legal effects.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application requires your consent during the registration process to send messages on your mobile phone or via the application for promotion purposes to inform you of any changes, advantages, or new activities added to the Application.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>In case you decide at any time that you do not want to receive promotional messages, you can deactivate the receiving of these messages through correspondence with us. But in this case, we do not ensure that you will enjoy our services fully.</span></li>
</ol>
<p>&nbsp;</p>
<p><b>FOURTEENTH: NOTICES</b></p>
<ol>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>Any notices you like to send to the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application must be sent through the features specified within the Application. Any notices sent outside the Application shall not be considered.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>Any notices the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application likes to send to you shall be either by publicizing via the Application or sending them to you via the mobile phone number you provided us with during the registration process. You are supposed to have known of the notice as soon as the notice is published via the Application or with the lapse of 24 hours from the time at which the message was sent to you.</span></li>
</ol>
<p>&nbsp;</p>
<p><b>FIFTEENTH: ASSIGNMENT OF RIGHTS AND OBLIGATIONS</b></p>
<ol>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application shall be entitled to transfer all the rights mentioned in this agreement to third parties without any objection by the user. All shall be bound to perform all their obligations mentioned in this agreement to the assignee immediately after they are notified of the assignment. The assignee shall be entitled to take all the legal procedures against whoever doesn’t perform his obligations under this agreement.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>You may not assign your obligations and rights under this agreement or authorize a third party to manage your account in the application except after getting written consent from us.</span></li>
</ol>
<p>&nbsp;</p>
<p><b>SIXTEENTH: LEGAL LIABILITY</b></p>
<ol>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>You shall be bound by all the laws and regulations in force within the KSA in relation to your use of the application and shall incur all the liability arising from your breach of the laws and regulations also you shall be bound by all the terms and conditions provided for in this agreement.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>In case of the breach by the user of any of the conditions or provisions of this agreement, The “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application shall be entitled to take an administrative action only within the Application. This action might be the suspension of membership for a time period or a permanent ban on the user in breach. He shall not be entitled in this case to register in the Application once more except with express consent of the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The party to take all the legal procedures he considers appropriate according to his discretion without having recourse against us and without the liability of the Application.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The customer is responsible under this Agreement and by law. And he is obligated to compensate us in the event that he submits fake or not serious purchase orders or whose purpose is to annoy us or waste our time, and he is also legally responsible to the shipping companies in this case.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>In case you violated this agreement, The “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application shall reserve its right to recover any amounts due to us from you as well as any losses or damages caused by you. And the Application shall be entitled to take the legal procedures or to resort to competent courts to file civil or criminal actions against you.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application does not ensure to take action against all the violations of this agreement. Not taking legal procedures in relation to any of the cases of violation shall not mean a waiver of our right to take these procedures at any time we consider appropriate.</span></li>
</ol>
<p>&nbsp;</p>
<p><b>SEVENTEENTH: LIMITS OF OUR LIABILITY</b></p>
<ol>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>You expressly agree that you are personally liable for the use by you of the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>we provide our services “as available” without any representations or warranties of any kind either express or implied, relating to the use of this application, content, or services provided by the Application.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>We shall not be liable for direct, indirect, consequential, or incidental losses or damages arising from:</span></li>
</ol>
<ul>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>Incorrect information in the services or recommendations relating to the services.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>Any dealings that take place between the service providers and customers outside the scope of the Application.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The quality of services offered by the service providers.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>loss of profits, sales, business, revenue, business interruption, or similar.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>loss of business opportunity, goodwill, reputation, or any indirect or consequential loss or damage.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>Any disclosure of your customer data by the service providers.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The user’s failure to keep the security, confidentiality, and privacy of account data.</span></li>
</ul>
<ol>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The application does not warrant that technical defects will be repaired or that the application or its servers are free from viruses or anything else that is harmful or destructive and you know that the applications on the internet might be exposed to corrupt data or its unavailability or the delay of its appearance and you accept that.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The application might be unavailable from time to time due to the acts of repair, maintenance, or development and you agree that the application shall not be bound to provide the technical support at any time.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>You acknowledge that the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application is an internet-based service and in spite of exerting our best to keep information safe, we cannot warrant that information received or transmitted by the user while using the application is safe at all times.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” application does not provide any warranty and shall not incur any liability in relation to the modernity, commonness, precision, and quality of the information received by the user via the application.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The liability of using the information that is received or reached by the user through the services of the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” application shall completely lie on this user.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” application does not provide any warranties that this application, its servers, or the messages are free from viruses, or other harmful components.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” application cannot assure that each user of the application is actually the person he claims to be.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The application shall not be liable in all cases for any direct, indirect, or dependent losses, loss of profits, or damage to the fame whatsoever as a result of disclosing your user name and/or password.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” application shall not be legally liable for losses or damages whatsoever that might arise from using this application including but limited to the direct and indirect damages.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>We might at any time modify, suspend or interrupt the services of the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” application without sending a notification to you thereof. Also, we might suspend your use of our services in case you violate these conditions and terms or in case you have misused these services from our point of view.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>Without prejudice to its other rights, the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” application shall be entitled to suspend or cancel your membership or your access to the application at any time without notice and for any reason and to cancel this agreement.</span></li>
</ol>
<p>&nbsp;</p>
<p><b>EIGHTEENTH: COMPENSATION</b></p>
<ol>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>You agree to compensate the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” application and to acquit its liability as well as defend it against all the actions and claims brought or claimed by third parties as a result of your use of the application or due to the violation of these conditions and terms or the breach of the rights of the other users.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The application excludes from its warranties, clauses, and conditions any financial losses that might be connected with the customer or the distorted reputation or any damages arising from your misuse of services or the application, and the application shall not incur any liability or claims in such cases.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” application, its officials, and employees shall not be legally liable to you or to another party for any direct or indirect loss or any other cost that might arise from, in connection with the performance of this agreement or the provisions of our services.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The user has to protect the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” application and its employees, defend them and compensate them for any losses resulting from any action or claim connected with the application or from an act or negligence by the user, his representative, or agents.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>you shall be bound to compensate for any losses or damages caused to the application as a result of any illegal use or not permitted by us.</span></li>
</ol>
<p>&nbsp;</p>
<p><b>NINETEENTH: MODIFICATIONS</b></p>
<ol>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The user shall be bound by the changes and modifications done by the application in relation to the applied policies and he has to accept any modifications considered by the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” application to be necessary, and we may at any time conduct modifications or improvements we consider appropriate and necessary for increasing the efficiency of the application. The user shall be bound by any directions or instructions provided by the application to him in this regard.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>You may not change any terms of this agreement amend or replace them without written consent from the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” application.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>We may modify or update these terms and conditions “User Agreement” in relation to the use of the application without notifying you thereof. Thus, you have to have access to this agreement regularly.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>You agree to be bound by all the additional conditions and terms that will be made available to you in connection with the use of any of the services available via the application. These additional conditions and terms are incorporated into this agreement.</span></li>
</ol>
<p>&nbsp;</p>
<p><b>TWENTIETH: CANCELLATION OF THE AGREEMENT</b></p>
<p><span style={{fontWeight: "400"}}>The agreement shall be deemed to have been automatically rescinded without the need for excuses or taking judicial procedures in any of the following cases:</span></p>
<ol>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>In case that you have breached any clause or term of the terms of this agreement above mentioned is proved while reserving all our rights to claim compensation for the damages resulting therefrom.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>Assigning or subleasing the account without our consent.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The non-performance by the user of any obligations provided for this agreement without taking serious actions to eliminate this breach while reserving our rights to claim compensations if necessary.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>Inability to document any information you provided us with.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>In case we decide at any time that your activities resulted in legal disputes with you or with other users.</span></li>
</ol>
<p>&nbsp;</p>
<p><b>TWENTY ONE: LINKS TO EXTERNAL WEBSITES</b></p>
<ol>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application may contain links to external websites of third parties, and we have no control over those websites or their contents, and we accept no liability for them or for any loss or damage that may arise from your use of them, and you agree not to involve us in any dispute you may have with any third party website.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>Before enabling any sharing functions of the website to communicate with or visit any such website, we recommend that you review and understand the terms &amp; conditions, privacy policy, and information-sharing functions of each such third-party website.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>The links on the “</span><b>Tygo</b><span style={{fontWeight: "400"}}>” Application do not constitute our consent to the use of such websites, and your use of such websites is at your own risk.</span></li>
</ol>
<p>&nbsp;</p>
<p><b>TWENTY TWO: APPLICABLE LAW</b></p>
<ol>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>These terms shall be governed by and construed in accordance with the applicable laws of the Kingdom of Saudi Arabia.</span></li>
<li style={{fontWeight: "400"}} aria-level={1}><span style={{fontWeight: "400"}}>If any of these terms shall be deemed unlawful, void, or unenforceable under applicable laws, that condition shall be deemed severable and shall not affect the validity and enforceability of the remaining conditions.</span></li>
</ol>
<p>&nbsp;</p>
<p><b>TWENTY THREE: CONFLICT</b></p>
<p><span style={{fontWeight: "400"}}>In the event that these terms conflict with any prior versions thereof, the current version shall prevail.</span></p>
<p>&nbsp;</p>
<p><b>TWENTY FOUR: LANGUAGE</b></p>
<p><span style={{fontWeight: "400"}}>These terms are drafted in both Arabic and English; The Arabic language will be adopted for the purpose of interpreting and implementing these terms and shall be applied before all the official and unofficial bodies if the English language conflicts with it.</span></p>
<p>&nbsp;</p>
<p><b>TWENTY FIVE: CONSENT</b></p>
<p><span style={{fontWeight: "400"}}>You acknowledge that you have read these terms and agree to be bound by their provisions. If you have any questions regarding these terms, do not hesitate to contact us through the means available on the Application.</span><span style={{fontWeight: "400"}}><br/>
</span></p>
<p>&nbsp;</p>
<p style={{textAlign: "center"}}><span style={{fontWeight: "400"}}>Copyright © </span><b>Tygo</b> <b>2022</b><span style={{fontWeight: "400"}}>,</span></p>
<p style={{textAlign: "center"}}><span style={{fontWeight: "400"}}>All Rights Reserved to </span><b>Future communication company&nbsp;</b></p>

		</div>
</div>
    )
}

export default Terms;


