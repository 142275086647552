
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { CompareCardContext } from "../../../components/compare/CompareCard";
import Dragable from "../../../components/compare/Dragable";
import Button from "../../../components/design/buttons/Button";
import Card from "../../../components/design/cards/card";
import Error from "../../../components/design/error/error";
import SelectOptions from "../../../components/design/selectors/select";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import { getFilterPlans } from "../../../services/plans.service";
import FiltterComponent from "../components/filtter";
import './plans.css';
import { FiGlobe } from 'react-icons/fi';
import { FaRegEnvelope } from 'react-icons/fa';
import { HiOutlinePhone } from 'react-icons/hi';
import { HiOutlineFilter } from 'react-icons/hi';
import { BiChevronDown } from 'react-icons/bi';
import Animate from "../../about/Animate";
import { ShimmerTitle } from "react-shimmer-effects-18";
import { ItemNotFound } from "../../404/404";


const OrderBy = [
    {
        value: 'price_asc',
        label: 'Price: Low to High',
    },
     {
        value: 'price_desc',
        label: 'Price: High to Low',
    },
    {
        value: 'name_asc',
        label: 'Name: A to Z',
    },
    {
        value: 'name_desc',
        label: 'Name: Z to A',
    },
]



const AllPlansScreen = () => {
    const { t,i18n } = useTranslation();
    const lang = i18n.language;
    const {addToCart} = useContext(CompareCardContext);

    const navigate = useNavigate();
    const [seeFilter, setSeeFilter] = React.useState(window.innerWidth > 768 ? "show all" : "hide all");
    const [plans, setPlans] = React.useState<any[]>([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    // const [plansPerPage, setPlansPerPage] = React.useState(9);
    const [totalPlans, setTotalPlans] = React.useState(0);
    const [totalPages, setTotalPages] = React.useState(0);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);

    const [name, setName] = React.useState('');
    const [orderBy, setOrderBy] = React.useState('');
    const [oldData, setOldData] = React.useState({
        "providerId": [] as number[],
        "mobileDate": [] as number[],
        "callsMin": [] as number[],
        "priceFrom": 0,
        "priceTo": 10000,
        "limit": 12,
        "contractTypeId": [] as number[],
        "validity": 0,
    });

    const [discount, setDiscount] = React.useState(false);

    const updatePlans = async (priceFrom: number, priceTo: number, providerId: number[], mobileDate: number[], callsMin: number[], contractTypeId: number[], validity:number, reset: boolean, currentPage: number, plansPerPage: number, orderBy: string, discount: boolean = false) => {
        setLoading(true);
        setError(false);
        const data = {
            "providerId": providerId,
            "mobileDate": mobileDate,
            "callsMin": callsMin,
            "name": name,
            "priceFrom": priceFrom, 
            "priceTo": priceTo,
            "orderBy": orderBy,
            "offset": reset === true ? 0 : currentPage,
            "limit": 12,
            "contractTypeId": contractTypeId,
            "validity": validity,
        }
        //if discount is true then we need to add the discount filter
        if(discount) {
            data["hasDiscountRate"] = true;
        }

        setOldData({
            "providerId": providerId,
            "mobileDate": mobileDate,
            "callsMin": callsMin,
            "priceFrom": priceFrom,
            "priceTo": priceTo,
            "limit": 12,
            "contractTypeId": contractTypeId,
            "validity": validity,
        })
        // "pageSize": 10,
        // "totalItemCount": 299,
        // "totalPagesCount": 30,
        // "pageIndex": 1,
        // "returnData": [...]
        try
        {
            const response = await getFilterPlans(data, lang);
            if(response.error) {
                setError(true);
                setLoading(false);
                return;
            }
            const a = response.returnData.map((plan: any) => {
                return {
                    id: plan.id,
                    name: plan.name,
                    image: plan.imageUrl,
                    title: plan.name,
                    type: plan.contractTypeName,
                    //discountRate is from 0 to 100 so we need to calculate the new price
                    newPrice: plan.discountRate === 0 ? plan.price : (plan.price - (plan.price * plan.discountRate)).toFixed(2),
                    hasDiscount: plan.discountRate === 0 ? false : true,
                    // hasDiscount: true,
                    // price: plan.price + ' SAR',
                    // messages: plan.smSForCurrentProvider == -1 ? "Unlimited" : (plan.smSForCurrentProvider).toLocaleString() + " Message",
                    // internet: plan.localInternt == -1 ? "Unlimited" : plan.localInternt + " GB",
                    // calls: plan.minutesInSideProvider == -1 ? "Unlimited" : plan.minutesInSideProvider + " Minutes",
                    price: plan.price.toFixed(1),
                    messages: plan.smSForCurrentProvider == -1 ? t("plans.unlimited") : (plan.smSForCurrentProvider).toLocaleString() + " " + t("plans.message"),
                    internet: plan.localInternt == -1 ? t("plans.unlimited") : (plan.localInternt/1000).toLocaleString() + " " + t("plans.gb"),
                    calls: plan.minutesInSideProvider == -1 ? t("plans.unlimited") : plan.minutesInSideProvider + " " + t("plans.minutes"),
                    discount: '-' + plan.discountRate * 100 + '%',
                }
            });
            setPlans(a);
            setTotalPlans(response.totalItemCount);
            setTotalPages(response.totalPagesCount);
            // setPlansPerPage(response.pageSize);
            setCurrentPage(response.pageIndex);
            setLoading(false);
            setError(false);
            
        } catch (e) {
            setError(true);
            setLoading(false);
        }
        
        
    }

    useEffect(() => {
        updatePlans(oldData.priceFrom, oldData.priceTo, oldData.providerId, oldData.mobileDate, oldData.callsMin, oldData.contractTypeId,oldData.validity,  false, currentPage, 12, orderBy, discount);
    }, [lang]);


    
    return (
        <div className="recommended-plans" style={{backgroundImage: "url(./media/hero/bg2.png)", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundAttachment: "fixed"}}>
            <div className="container">
            <Header />
            <div className="title text-center" >
                <Animate delay={0.1}>
                <h1 className="">{t('plans.title')}</h1>
                </Animate>
                <Animate delay={0.2}>
                <p className="para">{t('plans.subtitle')}</p>
                </Animate>
                <div style={
                    {
                        width: 'fit-content',
                        margin: 'auto'

                    }
                }>
                <Animate delay={0.3}>
                <Link to={"/stepper/1"} style={{textDecoration: "none"}}
                >

                    {/* background: transparent;
    border: none;
    color: #3eb6ae; */}
                 <div style={{background: "transparent", border: "none", color: "#3eb6ae", cursor: "pointer"}}>
                     {t('plans.best_plans_for_you')} 
                 <i className={(lang === 'ar' ? "fas fa-chevron-left" : "fas fa-chevron-right") + " mx-2"}></i>
                 </div>
                 </Link>
                </Animate>
                </div>
                
            </div>
            <div className="d-lg-none d-flex justify-content-between align-items-center mb-4">
                <Button color={"outline px-4 py-0"} inner={<div className="d-flex">
                                <p className="font-weight-light m-0">{t("plans.sort")}</p>
                                <div className="mx-2"></div>
                            <BiChevronDown className="" size={24} />
                            </div>} onClick={()=>{
                                // setSeeFilter("show sort")
                                if(seeFilter === "show sort")
                                    setSeeFilter("hide all");
                                else
                                    setSeeFilter("show sort");

                                

                            }
                            } />
                    <Button color={"outline px-4 py-0"} inner={<div className="d-flex align-items-center">
                            <p className="font-weight-light m-0">{t("plans.filter")}</p>
                            <div className="mx-2"></div>
                            <HiOutlineFilter className="" size={18} />
                        </div>} onClick={()=>
                            {
                                if(seeFilter === "show filter")
                                    setSeeFilter("hide all");
                                else
                                    setSeeFilter("show filter");
                            }
                            } />
                    
                
            </div> 
            <div className="row">
                <div className="col-12 mb-2">
                <div className="input-group mb-4" style={{borderRadius: '16px', padding: '6px 10px', background: '#FFF',}}>
                        <span className="bg-white p-3 " id="basic-addon2" style={{borderRadius: '0 12px 12px 0'}} onClick={() => updatePlans(oldData.priceFrom, oldData.priceTo, oldData.providerId, oldData.mobileDate, oldData.callsMin,  oldData.contractTypeId, oldData.validity, true, currentPage, 12, orderBy)}>
                            <i className="fas fa-search"></i>
                        </span>
                        <input type="text" className="form-control border-0" placeholder={t("plans.search_placeholder") as string}
                         aria-label="Recipient's username" aria-describedby="basic-addon2" style={{height: '54px', borderRadius: '12px 0 0 12px'}} onChange={(e) => setName(e.target.value)} onKeyPress={(e) => {
                            if(e.key === 'Enter') {
                                updatePlans(oldData.priceFrom, oldData.priceTo, oldData.providerId, oldData.mobileDate, oldData.callsMin, oldData.contractTypeId, oldData.validity, true, currentPage, 12, orderBy);
                            }
                        }}/>

                </div>
                </div>
            {   (seeFilter === "show filter" || seeFilter === "show all") &&
                
                <div className="col-12 col-lg-3">
                    
                    <Animate delay={0.1}>
                    <FiltterComponent onFilter={updatePlans} />
                    </Animate>
                    </div>}
            {
                loading ? <div className="col-12 col-lg-9">
                    {
                (seeFilter === "show sort" || seeFilter === "show all") &&
                <div className="row">
                <SelectOptions options={[
                    {
                        value: "price_desc",
                        name: t("plans.high_to_low")
                    },
                    {
                        value: "price_asc",
                        name: t("plans.low_to_High")
                    }
                ]} selected={orderBy} onSelect={(option: string) => (setOrderBy(option), updatePlans(oldData.priceFrom, oldData.priceTo, oldData.providerId, oldData.mobileDate, oldData.callsMin,oldData.contractTypeId,oldData.validity, true, currentPage, 12, option, ))} />
                </div>
               }
               
                    <div className="row">
                        {
                            [1,2,3,4,5,6,7,8,9,10].map((item: number) => (
                                <div className="col-12 col-md-6 col-xl-4" key={item}>
                                    <div className="details-card-out mb-4" key={item} style={{backgroundImage: "url(/media/top-ar.svg)"}}>
                                        <div className="details-card-in">
                                        <ShimmerTitle line={10} gap={10} variant="primary" />
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    
                    </div>
                : error ? <div className="col-12 col-lg-9">
                    <Error />
                    </div>
                : plans.length === 0 ? <div className="col-12 col-lg-9">
                    {/* <ItemNotFound title: "No Plans Found" description: "No Plans Found" /> */}
                    <ItemNotFound 
                    title={t("plans.no_plans_found")}
                    description={t("plans.description_no_plans_found")}
                    button={t("plans.clear_filter")}
                    onClick={() => {
                        window.location.reload();
                    }
                    }
                    />
                    </div> : <></>
                    }
                <div className="col-12 col-lg-9">
                    
               {
                (seeFilter === "show sort" || seeFilter === "show all") &&
                <div className="row">

                <SelectOptions options={[
                    {
                        value: "price_desc",
                        name: t("plans.high_to_low")
                    },
                    {
                        value: "price_asc",
                        name: t("plans.low_to_High")
                    }
                ]} selected={
                    orderBy === '' ? 'price_desc' : orderBy
                } onSelect={(option: string) => (setOrderBy(option), updatePlans(oldData.priceFrom, oldData.priceTo, oldData.providerId, oldData.mobileDate, oldData.callsMin,oldData.contractTypeId, oldData.validity,true, currentPage, 12, option))} />
                <span className="col-12 col-lg-5 mb-3 d-flex gap-3">   
                    <label className="check_box_filtter p-3" htmlFor="show_sort" style={{
                        background: 'transparent',
                        border: '1px solid #E8E8E8',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '16px',
                    }}>
                    <input type="checkbox" id="show_sort" name="show_sort" value="show_sort" checked={discount} onChange={() => {

                        updatePlans(oldData.priceFrom, oldData.priceTo, oldData.providerId, oldData.mobileDate, oldData.callsMin, oldData.contractTypeId, oldData.validity, true, 1, 12, orderBy,  !discount);
                        setDiscount(!discount);
                    }} /> {t("plans.discount")}
                    </label>
                </span>

                {/* <SelectOptions options={[
                    {
                        value: 9,
                        // name: "Elements per page: 9"
                        name: t("plans.per_page")+": 9"
                    },
                    {
                        value: 10,
                        name: t("plans.per_page")+": 10"
                    },
                    {
                        value: 20,
                        name: t("plans.per_page")+": 20"
                    },
                    {
                        value: 30,
                        name: t("plans.per_page")+": 30"
                    },
                ]} selected={plansPerPage} onSelect={(option: string) => (setPlansPerPage(parseInt(option)), updatePlans(oldData.priceFrom, oldData.priceTo, oldData.providerId, oldData.mobileDate, oldData.callsMin, true, currentPage, parseInt(option), orderBy))} /> */}

                </div>
               }
                <div className="row">
                    
                    {
                    plans.map((plan, index) => 
                        <div className="col-12 col-md-6 col-xl-4" key={index} id={"plan" + plan.id} style={{position: 'relative'}}>
                            <Dragable plan={plan} >
                            <Animate delay={index * 0.05} >
                            <Card
                                inner={
                                    <div className="plan-card" style={{minWidth: '260px'}}>
                                    <div className="d-flex align-items-start">
                                      <img src={plan.image
                                      } alt="" width="50" height="50" style={{borderRadius: "8px"}} />
                                      <div className="d-flex flex-column mx-3" style={{minHeight: "72px"}}>
                                        <strong className="">{plan.title}</strong>
                                        <p className="text-muted m-0"
                                        style={{
                                            display: 'flex',
                                            gap: '6px',
                                            flexDirection: 'row',
                                            width: 'max-content',
                                        }}>
                                            <span>{plan.type.toLowerCase().split('/')[0]}</span> 
                                            {
                                                plan.hasDiscount ?
                                            <span className=""> | <span style={{textDecoration: 'line-through'}}>{plan.price}</span></span>
                                                : <> | </> }<span className="price"> {plan.newPrice} {t("plans.sar")}</span>
                                        </p>
                                        {/* position: absolute;
    top: 0;
    left: 0px;
    padding: 9px;
    background: aquamarine;
    border-radius: 19px 8px 8px 5px;
} */}
                                        {
                                            plan.hasDiscount ?
                                            <div className="discount" style={
                                                i18n.language === "en" ? {
                                                
                                              position: 'absolute',
                                              top: '0',
                                              left: '12px',
                                              padding: '6px 10px',
                                              background: '#D4ECEB',
                                              color: '#3EB6AE',
                                              fontSize: '14px',
                                              fontWeight: '500',
                                              
                                              borderRadius: '19px 0px 8px 0px',
                                                } : {
                                                  position: 'absolute',
                                                  top: '0',
                                                  right: '12px',
                                                  padding: '6px 10px',
                                                  background: '#D4ECEB',
                                                  color: '#3EB6AE',
                                                  fontSize: '14px',
                                                  fontWeight: '500',
                                                  borderRadius: '0px 19px 0px 8px',
                                                }
                                              }>
                                                   {plan.discount}
                                                </div> : <></>
}
                                      </div>
                                  </div>
                                    
                                      <div className="d-flex d-lg-block justify-content-between" style={{maxWidth: '300px'}}>
                                              <div className="d-flex align-items-center my-3 ">
                                                  {/* <i className="fas fa-phone fa-1x mx-3" style={{color: '#3EB6AE'}}></i> */}
                                                  {/* <HiOutlinePhone className="mx-3" style={{color: '#3EB6AE', fontSize: '1.5rem'}} /> */}
                                                  <div className="me-3">                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.6 18.45 8.51 17.52 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z" stroke="#2F1952" stroke-width="1.5" stroke-miterlimit="10"/>
                                                      </svg>
                                                      </div>
                                                
                                                  <div>
                                                      <p className="font-weight-light  m-0">{plan.calls}</p>
                                                      <p className="font-weight-light text-muted m-0">{t("plans.calls")}</p>
                                                  </div>
                                              </div>
                                              <div className="d-none d-lg-flex
                                               align-items-center my-3">
                                                  {/* <i className="fas fa-comment fa-1x mx-3" style={{color: '#3EB6AE'}}></i> */}
                                                  {/* <FaRegEnvelope className="mx-3" style={{color: '#3EB6AE', fontSize: '1.5rem'}} /> */}
                                                  
                                                  <div className="me-3">
                                                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                              <path d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" stroke="#2F1952" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                                              <path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="#2F1952" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                                              </svg>
                                                              </div>

                                                  <div>
                                                      <p className="font-weight-light m-0">{plan.messages}</p>
                                                      <p className="font-weight-light text-muted m-0">{t("plans.messages")}</p>
                                                  </div>
                                              </div>
                                              <div className="d-flex align-items-center my-3">
                                                  {/* <i className="fas fa-globe fa-1x mx-3" style={{color: '#3EB6AE'}}></i> */}
                                                  {/* <FiGlobe className="mx-3" style={{color: '#3EB6AE', fontSize: '1.5rem'}} /> */}
                                                  <div className="me-3">

                                                                                                                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                  <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#2F1952" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                  <path d="M7.99998 3H8.99998C7.04998 8.84 7.04998 15.16 8.99998 21H7.99998" stroke="#2F1952" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                  <path d="M15 3C16.95 8.84 16.95 15.16 15 21" stroke="#2F1952" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                  <path d="M3 16V15C8.84 16.95 15.16 16.95 21 15V16" stroke="#2F1952" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                  <path d="M3 9.0001C8.84 7.0501 15.16 7.0501 21 9.0001" stroke="#2F1952" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                  </svg>
                                                  </div>

                                                  <div>
                                                      <p className="font-weight-light  m-0">{plan.internet}</p>
                                                      <p className="font-weight-light text-muted m-0">{t("plans.internet")}</p>
                                                  </div>
                                              </div>

                                      </div>
                                      <div className="d-flex mt-4 d-lg-none"> 
                                          <Button inner={t("plans.details")}
                                          onClick={
                                              () => navigate(`/plans/${plan.id}` )
                                              // () => setSelectedPlan(plan)
                                          } color="primary px-4" isrounded={false}/>
                                          <div className="mx-1"></div>
                                          <Button inner={t("plans.compare")}
                                           onClick={function (): void {
                                                  addToCart(plan, 'plan'+plan.id);
                                              } } color="primary-outline-primary px-4" isrounded={false}/>
                                      </div>
                                      <div className="d-none d-lg-block">
                                          <div className="row mt-4">
                                                  <div className="col-6 col-md-6 mb-1">
                                                      <Button inner={t("plans.details")}
                                                          onClick={
                                                          () => navigate(`/plans/${plan.id}` )
                                                         // () => setSelectedPlan(plan)
                                                      } color="primary" isfullWidth={true} isrounded={false} size="sm"/>
                                                  </div>
                                                  <div className="col-6 col-md-6 mb-1">
                                                      <Button inner={t("plans.compare")}
                                                          onClick={function (): void {
                                                              addToCart(plan, 'plan'+plan.id);
                                                          } } color="primary-outline-primary"
                                                          isfullWidth={true} isrounded={false} size="sm"/>
                                                  </div>
                                          </div>
                                          </div>
                                      </div>
                                }
                            />
                            </Animate>
                            </Dragable>
                        </div>
                    )}
                </div>

                <ul className="pagination justify-content-center">
                    {
                        // Array(totalPages).fill(0).map((_, index) => (
                        //     <li className="page-item" key={index}>
                        //         <a className={`page-link ${index + 1 === currentPage ? 'active' : ''}`} href="#" onClick={() => setCurrentPage(index + 1)}>{index + 1}</a>
                        //     </li>
                        // ))

                        // the top code generage the pagination numbers but it's too long so make it short to 5 numbers
                        
                        Array(totalPages).fill(0).map((_, index) => {
                            if ((index + 1 < currentPage + 3 && index + 1 > currentPage - 3)) {
                                return (
                                    <li className="page-item" key={index}>
                                        <span className={`page-link ${index + 1 === currentPage ? 'active' : ''}`} style={{cursor: 'pointer'}}
                                        onClick={() => 
                                        {
                                            setCurrentPage(index + 1);
                                            // alert(index + 1);
                                            updatePlans(oldData.priceFrom, oldData.priceTo, oldData.providerId, oldData.mobileDate, oldData.callsMin, oldData.contractTypeId,oldData.validity,false, (index + 1), 12, orderBy,  discount);
                                        }
                                        }>{index + 1}</span>

                                    </li>
                                )
                            } else if (index + 1 === currentPage - 3 || index + 1 === currentPage + 3) {
                                return (
                                    <li className="page-item" key={index}>
                                        <span className="page-link" >...</span>
                                    </li>
                                )
                            }
                        })




                    }
                </ul>

            </div>
            

            </div>

            
                    

        </div>
        <Footer />
        </div>
    );
    
};

export default AllPlansScreen;