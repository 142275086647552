import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../buttons/Button';
import './radius.css'

interface RadiusProps {
  from: number;
  to: number;
  step: number;
  min: number;
  max: number;
  courancy: string;
  onChange: (from: number, to: number) => void;
  buttons: {label: string, from: number, to: number}[];
  rtl: boolean;
  disabled: boolean;
}

const RangeSlider: React.FC<RadiusProps> = ({
  from,
  to,
  step,
  min,
  max,
  courancy,
  onChange,
  buttons,
  rtl,
  disabled
}) => {
  // const [rangeOneValue, setRangeOneValue] = useState(from);
  // const [rangeTwoValue, setRangeTwoValue] = useState(to);
//   const [selectedButton, setSelectedButton] = useState(null) as any;
  const {t, i18n} = useTranslation();
  useEffect(() => {
    updateView(from, to);
  }, [from, to]);

  const handleButtonClick = (button: {label: string, from: number, to: number}) => {
    // setSelectedButton(button);
    // setRangeOneValue(button.from);
    // setRangeTwoValue(button.to);
    updateView(button.from, button.to);
    onChange(button.from, button.to);

  }

  const updateView = (rangeOne: number, rangeTwo: number) => {
    const outputOne = document.querySelector('.outputOne')  as HTMLElement;
    const outputTwo = document.querySelector('.outputTwo') as HTMLElement;
    const inclRange = document.querySelector('.incl-range') as HTMLElement;

    if (rangeOne > rangeTwo) {
      inclRange.style.width = (rangeOne - rangeTwo) / max * 100 + '%';
      if(rtl)
        inclRange.style.right = rangeTwo / max * 100 + '%';
      else
        inclRange.style.left = rangeTwo / max * 100 + '%';
    } else {
      inclRange.style.width = (rangeTwo - rangeOne) / max * 100 + '%';
      if(rtl)
        inclRange.style.right = rangeOne / max * 100 + '%';
      else
        inclRange.style.left = rangeOne / max * 100 + '%';
      
    }

    outputOne.innerHTML = rangeOne.toString() + " " + courancy;
    // outputOne.style.left = rangeOne / max * 100 + '%';
    // outputTwo.style.left = rangeTwo / max * 100 + '%';
    // if(rtl)
    //   outputOne.style.right = rangeOne / max * 100 + '%';
    // else
    //   outputOne.style.left = rangeOne / max * 100 + '%';
    // if(rtl)
    //   outputTwo.style.right = rangeTwo / max * 100 + '%';
    // else
    //   outputTwo.style.left = rangeTwo / max * 100 + '%';
    


    if(rtl)
      outputOne.style.right = 'calc(-46px + ' + rangeOne / max * 100 + '%)';
    else
      outputOne.style.left = 'calc(0px + ' + rangeOne / max * 100 + '%)';
    if(rtl)
      outputTwo.style.right = 'calc(-46px + ' + rangeTwo / max * 100 + '%)';
    else
      outputTwo.style.left = 'calc(0px + ' + rangeTwo / max * 100 + '%)';
      outputTwo.innerHTML = rangeTwo.toString()+ " " + courancy;

    // if to is less than from then swap them
    // alert(rangeOne + " " + rangeTwo);
    // console.log(rangeOne + " " + rangeTwo);
    if(rangeTwo < rangeOne) {
      // updateView(rangeTwo, rangeOne);
      onChange(rangeTwo, rangeOne);
    }
  };

  const handleIndicatorClick = (value: number) => {
    if(disabled)
      return;
    // see the closest to the value if is it from or to
    if(Math.abs(from - value) < Math.abs(to - value)) {
      // setRangeOneValue(value);
      updateView(value, to);
      onChange(value, to);
    }
    else {
      // setRangeTwoValue(value);
      updateView(from, value);
      onChange(from, value);
    }
  }


  return (
    <div className='multi-range-slider '>
      <section className="range-slider container d-none d-lg-block"
      onClick={(event) => {
        // click position
        const x = event.clientX;
        // range position
        const range = event.currentTarget.getBoundingClientRect();
        // click position in range
        const click = rtl ? range.right - x : x - range.left;
        // click position in range in %
        const clickInPercent = click / range.width;
        // click position in range in value
        const clickInValue = clickInPercent * (max - min) + min;
        // abs
        const abs = Math.abs(clickInValue);
        // round to step
        const round = Math.round(abs / step) * step;
        // set value
        if(Math.abs(from - round) < Math.abs(to - round)) {
          if (disabled)
            return;
          // setRangeOneValue(round);
          updateView(round, to);
          onChange(round, to);
        }
        else {
          if (disabled)
            return;
          // setRangeTwoValue(round);
          updateView(from, round);
          onChange(from, round);
        }
      }}>
        <span className="output outputOne"></span>
        <span className="output outputTwo"></span>
        <span className="full-range"
        
        >

        </span>
        <span className="incl-range"></span>
        <input
          name="rangeOne"
          min={min}
          max={max}
          step={step}
          type="range"
          value={from}
          onChange={(event) => {
            if (disabled)
              return;
            const value = parseInt(event.target.value);
            // setRangeOneValue(value);
            onChange(value, to);
          }}
        />
        <input
          name="rangeTwo"
          min={min}
          max={max}
          step={step}
          type="range"
          value={to}
          onChange={(event) => {
            if (disabled)
              return;
            const value = parseInt(event.target.value);
            // setRangeTwoValue(value);
            onChange(from, value);
          }}
        />
      </section>
      <div className="range-indicators d-none d-lg-flex">
        {/* 6 values from min to max */}
        <span className="indicator" onClick={() => handleIndicatorClick(min)}
        >{min} {courancy}</span>
        <span className="indicator" onClick={() => handleIndicatorClick(min + (max - min) / 5)}>{min + (max - min) / 5} {courancy}</span>
        <span className="indicator" onClick={() => handleIndicatorClick(min + (max - min) / 5  * 2)}>{min + (max - min) / 5 * 2} {courancy}</span>
        <span className="indicator" onClick={() => handleIndicatorClick(min + (max - min) / 5  * 3)}>{min + (max - min) / 5 * 3} {courancy}</span>
        <span className="indicator" onClick={() => handleIndicatorClick(min + (max - min) / 5  * 4)}>{min + (max - min) / 5 * 4} {courancy}</span>
        <span className="indicator" onClick={() => handleIndicatorClick(max)}>{max} {courancy}</span>
      </div>
      <div className="buttons my-3">
        <span className='d-none d-lg-block'>{t('stepper.quick_answers')}</span>
        <div className="button-container">
          {buttons.
          sort((a, b) => a.from - b.from)
          .map(button => (
            // <button 
            //   key={button.label}
            //   onClick={() => handleButtonClick(button)}
            // >
            //   {button.label}
            // </button>
            <Button inner={
              <span>{button.label}</span>
            } color={from >= button.from && (to === button.to || button.to === max)
               ? "primary-outline" : "secondary"}
            onClick={() => handleButtonClick(button)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RangeSlider;



