import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getProviders } from "../../../services/providers.service";
import FiltterSection from "./filtterSection";
import './filtter.css';
import { ShimmerTitle } from "react-shimmer-effects-18";

import { getFilterPlans, getPlans } from "../../../services/plans.service";
import Button, { ButtonSquare } from "../../../components/design/buttons/Button";
const FiltterComponent = ({onFilter} : any) => {
    const {t, i18n} = useTranslation();

    const [providers, setProviders] = useState([
        {
            id: 1,
            name: "Mobily",
            isChecked: false,
            logo: "./media/logos/jawali.png"
        },
        {
            id: 2,
            name: "Zain",
            isChecked: false,
            logo: "./media/logos/zain.png"
        },
        {
            id: 3,
            name: "STC",
            isChecked: false,
            logo: "./media/logos/stc.png"
        },
        {
            id: 4,
            name: "Virgin",
            isChecked: false,
            logo: "./media/logos/virgin.png"
        }
    ]);

    const [loadingProviders, setLoadingProviders] = useState(true);
    const [errorProviders, setErrorProviders] = useState(false);

    const [internets, setInternets] = useState([
        {
            id: 10000,
            name: "10 " + t("plans.gb"),
            isChecked: false,
        },
        {
            id: 25000,
            name: "25 " + t("plans.gb"),
            isChecked: false,
        },
        {
            id: 50000,
            name: "50 " + t("plans.gb"),
            isChecked: false,
        },
        {
            id: 100000,
            name: "100 " + t("plans.gb"),
            isChecked: false,
        },

        // {
        //     id: 250000,
        //     name: "250 " + t("plans.gb"),
        //     isChecked: false,
        // },
        {
            id: 500000,
            name: "500 " + t("plans.gb"),
            isChecked: false,
        },



        {
            id: -1,
            name:t("plans.unlimited"),
            isChecked: false,
        }
    ]);

    // Calls Minutes

    const [callsMinutes, setCallsMinutes] = useState([
        {
            id: 100,
            name: "100 " + t("plans.minutes"),
            isChecked: false,
        },
        {
            id: 250,
            name: "250 " + t("plans.minutes"),
            isChecked: false,
        },
        {
            id: 500,
            name: "500 " + t("plans.minutes"),
            isChecked: false,
        },
    
        {
            id: -1,
            name: t("plans.unlimited"),
            isChecked: false,
        }
    ]);

    // Validity
    const [validities, setValidities] = useState([
        {
            id: 1,
            name: "30 " + t("plans.days"),
            isChecked: false,
            value: 30
        },
        {
            id: 2,
            name: "60 " + t("plans.days"),
            isChecked: false,
            value: 60
        },
        {
            id: 3,
            name: "90 " + t("plans.days"),
            isChecked: false,
            value: 90
        },
    ]);


    // price
    const [priceMin, setPriceMin] = useState(0);
    const [priceMax, setPriceMax] = useState(10000);

    const [prices, setPrices] = useState([
        {
            id: 1,
            name: "100-200 " + t("plans.sar"),
            min: 100,
            max: 200
        },
        {
            id: 2,
            name: "200-300 " + t("plans.sar"),
            min: 200,
            max: 300
        },
        {
            id: 3,
            name: "300-400 " + t("plans.sar"),
            min: 300,
            max: 400
        },
        {
            id: 4,
            name: "400-1,000 " + t("plans.sar"),
            min: 400,
            max: 1000
        }
    ]);

    const [simTypes, setSimTypes] = useState([
        {
            id: 1,
            name: t("plans.prepaid"),
            isChecked: false,
        },
        {
            id: 2,
            name: t("plans.postpaid"),
            isChecked: false,
        }
    ]);

    const checkIfProviderHasPlans = async (providerId: number) => {
        const data = {
            "providerId": [providerId],
            "mobileDate": internets.filter(p => p.isChecked).map(p => p.id),
            "callsMin": callsMinutes.filter(p => p.isChecked).map(p => p.id),
            "name":  "",
            "priceFrom": priceMin,
            "priceTo": priceMax,
            "orderBy": "price",
            "offset": 0,
            "limit": 200,
            "contractTypeId": simTypes.filter(p => p.isChecked).map(p => p.id)
        }

        const plans = await getFilterPlans(data, i18n.language);
        return plans.totalItemCount;
        }
    
    const checkProviderAvailability = async (
        providersa: any
    ) =>
    {
        const promises = await providersa.map(async (provider: any) => {
            const response :any= await checkIfProviderHasPlans(provider.id);
            console.log(response);
            provider.numberOfPlans = response;
            console.log(provider.numberOfPlans);
            return provider;
        });

        setProviders(await Promise.all(promises));
        setLoadingProviders(false);
    }

    const Filter = (
        providerss : any 
    ) => {
        
        onFilter(priceMin, priceMax, providerss.filter(p => p.isChecked).map(p => p.id), internets.filter(p => p.isChecked).map(p => p.id), callsMinutes.filter(p => p.isChecked).map(p => p.id), simTypes.filter(p => p.isChecked).map(p => p.id), 
        validities.filter(p => p.isChecked)[0].value || 0);
        setLoadingProviders(true);
        checkProviderAvailability(providerss);
    }

    const fetchProviders = async () => {
        try {
            const response = await getProviders(i18n.language);
            const providersss = response.returnData.map((p: any) => {
                return {
                    id: p.id,
                    name: p.name,
                    isChecked: false,
                    logo: p.imagePathSmall
                }
            });
            setProviders(providersss);
            setLoadingProviders(false);
            Filter(providersss);
        } catch (error) {
            setErrorProviders(true);
            setLoadingProviders(false);
        }
    };
    
    const[show_more, setShow_more] = useState(false);


    useEffect(() => {
        fetchProviders();
    }, [priceMin, priceMax, internets, callsMinutes, i18n.language, simTypes, validities]);
    
    return (
        <div className="plans-filtter bg-lg-white p-lg-3 " style={{borderRadius:"16px"}}>
            <strong className="d-none mb-3 d-lg-block"
            >{t("plans.filter_options")}</strong>
            <FiltterSection title={t("plans.price")}>
                <div className="input-group glow-on-focus my-3" style={{border:"1px solid #F4F6F8", borderRadius:"8px"}}>
                    <input type="number" className="form-control" placeholder="Set Min. Price" aria-label="min" aria-describedby="basic-addon1" value={priceMin} onChange={(e) => setPriceMin(parseInt(e.target.value))} style={{border:"none"}}/>
                    <div className="">
                        <span className="input-group-text d-flex align-items-center justify-content-center " id="basic-addon1" style={{width: "58px", height: "48px",background:"#F4F6F8" ,border:"none", borderRadius:"6px"}}>
                            {/* <i className="fas fa-dollar-sign"></i> */}
                            {t("plans.sar")}

                        </span>
                    </div>

                </div>
                <div className="input-group glow-on-focus my-3" style={{border:"1px solid #F4F6F8", borderRadius:"8px"}}>
                    <input type="number" className="form-control" placeholder="Set Max. Price" aria-label="max" aria-describedby="basic-addon1" value={priceMax} onChange={(e) => setPriceMax(parseInt(e.target.value))} style={{border:"none"}}/>
                    <div className="">   
                        <span className="input-group-text d-flex align-items-center justify-content-center" id="basic-addon1" style={{width: "58px", height: "48px",background:"#F4F6F8" ,border:"none", borderRadius:"6px"}}>
                            {/* <i className="fas fa-dollar-sign"></i> */}
                            {t("plans.sar")}

                        </span>
                    </div>
                </div>

                {
                    prices.map((price: any) => (
                        <button className={`mb-2 me-2 button-select ${priceMin === price.min && priceMax === price.max ? "active" : ""}`} key={price.id} onClick={(e) => {
                            if(priceMin === price.min && priceMax === price.max) {
                                setPriceMin(0);
                                setPriceMax(0);
                                e.currentTarget.blur()
                            } else {
                            
                            setPriceMin(price.min);
                            setPriceMax(price.max);
                            }
                        }
                        }>{price.name}</button>
                        
                    ))
                }
                {/* {
                    priceMin !== 0 && priceMax !== 0 && <button className="mb-2 me-2 button-select w-100 active" onClick={() => {
                        setPriceMin(0);
                        setPriceMax(0);
                    }
                    }>
                        {t("plans.clear")}
                    </button>
                } */}




                
            </FiltterSection>
            <FiltterSection title={t("plans.contract_type")}>
                {
                    simTypes.map((simType: any) => (
                            <div className="d-flex align-items-center my-2" key={simType.id} onClick={() => {
                                const newSimTypes = simTypes.map((s: any) => {
                                    if (s.id === simType.id) {
                                        s.isChecked = !s.isChecked;
                                    }
                                    return s;
                                });
                                setSimTypes(newSimTypes);
                            }} style={{cursor: "pointer"}}>
                                <label className="check_box_filtter ">
                                <input type="checkbox" className="" checked={simType.isChecked} />

                                </label>
                                <span className="mx-2">{simType.name}</span>
                            </div>
                    ))
                }
            </FiltterSection>

            <FiltterSection title={t("plans.provider")}>
                {
                    loadingProviders  ?
                    <ShimmerTitle line={10} gap={10} variant="primary" />

                    :
                    providers.map((provider: any) => (
                            <div className={"d-flex align-items-center my-2" + (provider.numberOfPlans ?"" :" d-none")}
                             key={provider.id} onClick={() => {
                                const newProviders = providers.map((p: any) => {
                                    if (p.id === provider.id) {
                                        p.isChecked = !p.isChecked;
                                    }
                                    return p;
                                });
                                setProviders(newProviders);
                                Filter(newProviders);
                            }} style={{cursor: "pointer"}}>
                                <label className="check_box_filtter ">
                                <input type="checkbox" className="" checked={provider.isChecked} />
                                </label>
                                <img src={provider.logo} alt={provider.name} style={{width: "1rem"}} className="mx-2" />
                                <span className="m">{provider.name} ({provider.numberOfPlans})</span>
                            </div>
                    ))
                }
            </FiltterSection>
            <FiltterSection title={t("plans.mobile_data")}>
                {
                    internets.map((internet: any) => (
                            <div className="d-flex align-items-center my-2" key={internet.id} onClick={() => {
                                const newInternets = internets.map((i: any) => {
                                    if (i.id === internet.id) {
                                        i.isChecked = !i.isChecked;
                                    }
                                    return i;
                                });
                                setInternets(newInternets);
                            }} style={{cursor: "pointer"}}>
                                 <label className="check_box_filtter ">

                                <input type="checkbox" className="" checked={internet.isChecked} />
                                </label>
                                <span className="mx-2">{internet.name}</span>
                                </div>
                    ))
                }
            </FiltterSection>
            <FiltterSection title={t("plans.calls_minutes")}>
                {
                    callsMinutes.map((callMinute: any) => (
                            <div className="d-flex align-items-center my-2" key={callMinute.id} onClick={() => {
                                const newCallsMinutes = callsMinutes.map((c: any) => {
                                    if (c.id === callMinute.id) {
                                        c.isChecked = !c.isChecked;
                                    }
                                    return c;
                                });
                                setCallsMinutes(newCallsMinutes);
                            }} style={{cursor: "pointer"}}>
                                <label className="check_box_filtter ">
                                <input type="checkbox" className="" checked={callMinute.isChecked} />

                                </label>
                                <span className="mx-2">{callMinute.name}</span>
                            </div>
                    ))
                }
            </FiltterSection>
            {
                show_more ?
        
            <FiltterSection title={t("plans.validity")}>
                {
                    validities.map((validity: any) => (
                            <div className="d-flex align-items-center my-2" key={validity.id} onClick={() => {
                                const newValidities = validities.map((v: any) => {
                                    if (v.id === validity.id) {
                                        v.isChecked = !v.isChecked;
                                    } else {
                                        v.isChecked = false;
                                    }
                                    return v;
                                });
                                
                                setValidities(newValidities);
                            }} style={{cursor: "pointer"}}>
                                <label className="check_box_filtter ">
                                <input type="checkbox" className="" checked={validity.isChecked} />

                                </label>
                                <span className="mx-2">{validity.name}</span>
                            </div>
                    ))
                }
            </FiltterSection>
            : null
    }
            <Button inner={
                show_more ? t("plans.seeless") : t("plans.seemore")
            } color="
            btn-size-sm
            primary-outline-primary btn full-width "
                         size='sm'
                         
                        onClick={() => {
                            setShow_more(!show_more);
                        }}/>

        
            
        </div>
    );
};

export default FiltterComponent;
